<template>
	<div
		:class="{
			'left-icon': icon,
			'right-icon': isTypePassword,
			'error-wrap': hasError,
		}"
	>
		<div class="d-flex justify-content-between gap">
			<div class="col px-0">
				<label v-if="label" :for="id" class="control-label">{{ label }}</label>
			</div>
			<span v-if="maxlength != undefined && isTypeText && showCharacterCount && !useInlineMaxLength" class="character-counter para-3"
				>{{ value ? value.length : 0 }}/{{ maxlength }}</span
			>
		</div>
		<div class="d-flex align-items-center flex-wrap para-2">
			<label v-if="prefix" class="mb-0 pr-1 text-truncate">{{ prefix }}</label>
			<div class="col px-0">
				<InlineSvg class="input-icon" v-if="icon" :src="icon" :width="iconWidth" />
				<div
					:class="{
						suffixed: suffix || useInlineMaxLength,
						'form-control': suffix || useInlineMaxLength,
						error: hasError,
						primary: primary,
						disabled: disabled,
					}"
				>
					<input
						:id="id"
						v-model="localValue"
						v-two-dp="hasPrecision && isTypeNumber"
						:class="[
							inputClass,
							{
								'form-control': !suffix && !useInlineMaxLength,
								'text-lowercase': lowercase,
								'text-uppercase': uppercase,
								error: hasError,
								primary: primary,
							},
						]"
						:type="typeUse"
						:name="name"
						:placeholder="placeholder"
						:disabled="disabled"
						:maxlength="maxlength"
						:max="max"
						:min="min"
						:autocomplete="disableAutoComplete ? 'disable-autocomplete' : 'on'"
						ref="inputElement"
						@keydown="e => $emit('keydown', e)"
						@keyup="e => $emit('keyup', e)"
						@change="e => $emit('change', e)"
						@input="e => $emit('input', e)"
						@blur="e => inputLostFocus(e)"
						@focus="e => inputFocused(e)"
					/>
					<span v-if="suffix" class="suffix">{{ suffix }}</span>
					<span v-else-if="maxlength && useInlineMaxLength && showInlineMaxLength" class="suffix small">
						{{ value ? value.length : 0 }}/{{ maxlength }}
					</span>
				</div>
				<InlineSvg
					v-if="isTypePassword && showPassword"
					class="show-password-icon"
					src="images/icons/ic-visible.svg"
					:width="iconWidth"
					@click="() => setShowPassword(false)"
				/>
				<InlineSvg
					v-if="isTypePassword && !showPassword"
					class="show-password-icon"
					src="images/icons/ic-hidden.svg"
					:width="iconWidth"
					@click="() => setShowPassword(true)"
				/>
			</div>
		</div>
		<div class="para-3 error-text" v-if="hasError || warning">
			<span v-if="hasError" class="text-bold error-message"><i></i>{{ error[0] }}</span>
			<span v-if="warning" style="color: #00cfcc"><br v-if="hasError" /><i></i>{{ warning }}</span>
		</div>
		<div class="helper-text" v-if="this.$slots.helperText">
			<slot name="helperText"></slot>
		</div>
	</div>
</template>
<script>
	import { UUID } from '~/store/modules/Global.js'
	import { debounce as _debounce } from 'lodash'

	export default {
		name: 'AppInput',
		props: {
			value: {
				type: [String, Number],
				default: '',
			},
			id: {
				type: String,
				default: UUID(),
			},
			label: {
				type: String,
				default: '',
			},
			name: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
			},
			icon: {
				type: String,
				default: undefined,
			},
			type: {
				type: String,
				default: 'text',
				validator(value) {
					return ['text', 'number', 'email', 'password'].includes(value)
				},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			rows: {
				type: Number,
				default: 2,
			},
			cols: {
				type: Number,
				default: 20,
			},
			limit: {
				type: Number,
			},
			prefix: {
				type: String,
			},
			suffix: {
				type: String,
			},
			inputClass: {
				type: [Array, String],
			},
			iconWidth: {
				type: String,
			},
			maxlength: {
				type: [Number, String],
				default: undefined,
			},
			showCharacterCount: {
				type: Boolean,
				default: true,
			},
			hasPrecision: {
				type: Boolean,
			},
			error: {
				type: Array,
			},
			warning: {
				type: String,
			},
			min: {
				type: Number,
				default: undefined,
			},
			max: {
				type: Number,
				default: undefined,
			},
			uppercase: {
				type: Boolean,
				default: undefined,
			},
			lowercase: {
				type: Boolean,
				default: undefined,
			},
			primary: {
				type: Boolean,
				default: undefined,
			},
			disableAutoComplete: {
				type: Boolean,
				default: false,
			},
			useInlineMaxLength: {
				type: Boolean,
				default: false,
			},
		},
		directives: {
			'two-dp': {
				bind: function twoDp(el, binding) {
					if (binding.value) {
						el.addEventListener('keyup', e => {
							// evolved from https://stackoverflow.com/a/52685887/9614402
							const keyCode = e.keyCode ? e.keyCode : e.which
							const value = e.target.value
							const i = value.indexOf('.')

							if ((keyCode < 48 || keyCode > 57) && !(keyCode === 46 && i === -1)) {
								// 46 is dot
								// only allow numbers and one dot
								e.preventDefault()
							} else {
								const splits = e.target.value.split('.')
								if (splits.length > 1 && splits[1].length > 1) {
									e.target.value = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
									e.target.dispatchEvent(new Event('input'))
								}
							}
						})
						// modify the initial value if it's not 2dp
						const inputElement = el.nodeName === 'INPUT' ? el : el.querySelector('input')
						const stringValue = inputElement.value
						const splits = stringValue.split('.')
						if (splits.length > 1 && splits[1]?.length > 2) {
							const newValue = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
							if (inputElement.value !== newValue) {
								inputElement.value = newValue
								inputElement.dispatchEvent(new Event('input'))
							}
						}
					}
				},
			},
		},
		model: {
			prop: 'value',
			event: 'valueChange',
		},
		computed: {
			localValue: {
				get: function getter() {
					return this.value
				},
				set: function setter(value) {
					if (value && this.lowercase) value = value.toLowerCase()
					if (value && this.uppercase) value = value.toUpperCase()
					this.$emit('valueChange', value)
				},
			},
			typeUse() {
				if (this.isTypePassword && this.showPassword) {
					return 'text'
				}
				return this.type
			},
			isTypeText() {
				return this.type === 'text'
			},
			isTypeNumber() {
				return this.type === 'number'
			},
			isTypeEmail() {
				return this.type === 'email'
			},
			isTypePassword() {
				return this.type === 'password'
			},
			hasError() {
				return this.error && this.error.length
			},
		},
		methods: {
			inputFocused(e) {
				e.target.addEventListener('wheel', this.ignoreWheelScroll, { passive: false })
				this.showInlineMaxLength = true
				this.$emit('focus', e)
			},
			inputLostFocus(e) {
				e.target.removeEventListener('wheel', this.ignoreWheelScroll)
				this.showInlineMaxLength = false
				this.$emit('blur', e)
				this.reset()
			},
			ignoreWheelScroll(e) {
				e.preventDefault()
			},
			focus() {
				this.$refs.inputElement.focus()
			},
			// eslint-disable-next-line func-names
			setShowPassword: _debounce(function(showPassword) {
				this.showPassword = showPassword
				this.focus()
			}, 100),
			reset() {
				this.showPassword = false
			},
		},
		data() {
			return {
				showInlineMaxLength: false,
				showPassword: false,
			}
		},
	}
</script>
<style lang="scss" scoped>
	.posAbs {
		position: absolute;
		bottom: -23px;
		left: 0;
		font-size: 12px;
	}

	.left-icon {
		position: relative;
		align-self: center;
		color: var(--stan-text-dark-color);
		i {
			font-size: 18px;
			line-height: 18px;
			top: 15px;
			left: 16px;
			right: 16px;
			display: inline-block;
			position: absolute;
		}
		svg.input-icon,
		img.input-icon {
			font-size: 18px;
			line-height: 18px;
			top: 15px;
			left: 13px;
			display: inline-block;
			position: absolute;
			width: 18px;
			height: 18px;
		}

		input {
			padding-left: 44px;
			color: var(--stan-text-dark-color);
		}
	}

	.sm-icon-padding {
		padding-left: 26px !important;
	}

	.right-icon {
		svg.show-password-icon {
			font-size: 18px;
			line-height: 18px;
			top: 15px;
			right: 13px;
			display: inline-block;
			position: absolute;
			width: 18px;
			height: 18px;
			cursor: pointer;
		}

		input {
			padding-right: 44px;
		}
	}

	.error-message {
		color: var(--stan-text-negative-color);
	}

	.suffixed {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		input {
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
			line-height: 1;
			color: var(--stan-text-dark-color);
			width: 0;
		}
		.suffix {
			color: #808db6;
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
			&.small {
				font-size: 12px;
			}
		}
		&.disabled {
			cursor: not-allowed;
			color: var(--stan-text-light-color) !important;
			background: var(--stan-primary-light-color) !important;
			-webkit-text-fill-color: var(--stan-text-light-color);
			-webkit-opacity: 1;
		}
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
	input:focus {
		box-shadow: none;
		outline: none;
	}
	input:disabled {
		cursor: not-allowed;
		color: var(--stan-text-light-color) !important;
		background: var(--stan-primary-light-color) !important;
		-webkit-text-fill-color: var(--stan-text-light-color);
		-webkit-opacity: 1;
	}
	.character-counter {
		color: var(--stan-text-light-color);
		margin-left: auto;
		padding: 0;
		align-self: center;
	}
	.helper-text {
		color: var(--stan-text-light-color);
		font-size: 13px;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.26px;
		padding-top: 6px;
		padding-left: 6px;
	}
	.error-text {
		font-size: 13px;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.26px;
		padding-top: 6px;
		padding-left: 6px;
	}
</style>
