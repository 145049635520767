var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticClass: "d-flex align-items-center justify-content-start mb-3"
          },
          [
            _c("label", { staticClass: "text-bold mr-2 mb-0" }, [
              _vm._v(_vm._s(_vm.$t("Enable Countdown Timer")))
            ]),
            _vm._v(" "),
            _c("AppToggle", {
              model: {
                value: _vm.value.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.value, "enabled", $$v)
                },
                expression: "value.enabled"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "AppAccordion",
          [
            _c(
              "AppAccordionItem",
              {
                staticClass: "mb-0 overflow-visible shadow-none",
                attrs: {
                  showHeader: false,
                  isWrapper: true,
                  name: _vm.accordionId
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column mb-3" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Countdown Description")
                    ]),
                    _vm._v(" "),
                    _c("AppInput", {
                      staticClass: "form-group mb-0",
                      attrs: {
                        maxlength: "50",
                        showCharacterCount: false,
                        placeholder: "Countdown Description Here"
                      },
                      model: {
                        value: _vm.value.title,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "title", $$v)
                        },
                        expression: "value.title"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    key: _vm.resetKey,
                    staticClass: "d-flex mb-3 d-flex flex-row mb-3 gap-15px"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-50" },
                      [
                        _c("AppFlatPickr", {
                          ref: "calendar",
                          class: {
                            "form-error": _vm.validation.end_date_time.$error
                          },
                          attrs: {
                            custom_class: "form-control",
                            placeholder: "End Date",
                            min_date: _vm.minDateStart
                          },
                          on: { onDateSelect: _vm.onDateTimeSelected },
                          model: {
                            value: _vm.endDate,
                            callback: function($$v) {
                              _vm.endDate = $$v
                            },
                            expression: "endDate"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-50" }, [
                      _c(
                        "div",
                        {
                          staticClass: "datetime-dropdown time",
                          class: {
                            "form-error": _vm.validation.end_date_time.$error
                          }
                        },
                        [
                          _c("AppDropdown", {
                            staticClass: "form-control py-0",
                            attrs: {
                              placeholder: "End Time",
                              options: _vm.startTimeOptions,
                              searchable: false,
                              label: "name",
                              reduce: "value",
                              rightAlign: false,
                              disabled: _vm.startTimeOptions.length < 1
                            },
                            on: { select: _vm.onDateTimeSelected },
                            model: {
                              value: _vm.endTimeValue,
                              callback: function($$v) {
                                _vm.endTimeValue = $$v
                              },
                              expression: "endTimeValue"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }