var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrapper" },
    [
      _vm.screenSize > 768 || (_vm.screenSize <= 768 && !_vm.showCoursesList)
        ? _c("div", { staticClass: "main-section" }, [
            _c(
              "div",
              { staticClass: "exit-preview-banner", on: { click: _vm.close } },
              [_vm._v("\n\t\t\tClick to exit preview\n\t\t")]
            ),
            _vm._v(" "),
            _vm.showHome
              ? _c("div", { staticClass: "lesson-details" }, [
                  _vm.bannerImage
                    ? _c(
                        "div",
                        { staticClass: "image-wrapper" },
                        [
                          _c("AppImage", {
                            staticClass: "banner-image",
                            attrs: { src: _vm.bannerImage }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        gap: "40px",
                        "margin-top": "24px"
                      }
                    },
                    [
                      _vm.course.data.title
                        ? _c("h1", { staticClass: "title-text" }, [
                            _vm._v(_vm._s(_vm.course.data.title))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.course.data.description
                        ? _c(
                            "div",
                            { staticClass: "description" },
                            [
                              _vm.course.data.description
                                ? _c("TipTapViewer", {
                                    staticClass: "px-5-mod mb-5",
                                    attrs: { "read-only": "" },
                                    model: {
                                      value: _vm.course.data.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.course.data,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "course.data.description"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "button-wrapper" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canShowNext,
                            expression: "canShowNext"
                          }
                        ],
                        staticClass: "controller-button next-button",
                        on: { click: _vm.firstLesson }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "controller-button-text align-items-end flex-shrink-full"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "para-3 button-header-text" },
                              [_vm._v("First Lesson:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "para-3 font-semibold button-subheader-text text-ellipsis text-right"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.nextLessonTitle) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "8",
                              height: "16",
                              fill: "var(--stan-store-custom-highlight-color)",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z",
                                fill: "inherit"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ])
              : _vm.currentLesson
              ? _c(
                  "div",
                  { staticClass: "lesson-details" },
                  [
                    _vm.lessonHasVideo
                      ? _c("Video", {
                          key: "video",
                          staticClass: "pages-block py-6 px-0",
                          attrs: {
                            "video-src": _vm.currentLesson.data.video,
                            "video-stream-src":
                              _vm.currentLesson.data.video_stream,
                            "fcp-complete": true,
                            showMask: false,
                            videoId: "preview-course-desktop-video"
                          }
                        })
                      : !_vm.hideVideo
                      ? _c("div", { staticClass: "video-placeholder mb-6" }, [
                          _c("div", { staticClass: "video-button-wrapper" }, [
                            _c("div", { staticClass: "video-icon" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://assets.stanwith.me/graphics/video-play.svg"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("No Video Yet")])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "40px"
                        }
                      },
                      [
                        _c("h1", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.currentLesson.data.title))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "description" },
                          [
                            _vm.currentLesson.data.description
                              ? _c("TipTapViewer", {
                                  staticClass: "mb-5 px-5-mod",
                                  attrs: { "read-only": "" },
                                  model: {
                                    value: _vm.currentLesson.data.description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.currentLesson.data,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "currentLesson.data.description"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentLesson.data.digital_assets.length
                              ? _c(
                                  "div",
                                  { staticClass: "lesson-support-wrapper" },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "lesson-support-title" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Supporting Materials"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "supporting-materials-list"
                                      },
                                      _vm._l(
                                        _vm.currentLesson.data.digital_assets,
                                        function(asset, index) {
                                          return _c("li", { key: index }, [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.downloadFile(
                                                      asset
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "download-icon",
                                                  attrs: { src: _vm.iconUrl }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "download-text"
                                                  },
                                                  [_vm._v(_vm._s(asset.name))]
                                                )
                                              ]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "button-wrapper" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showHome && _vm.canShowPrev,
                              expression: "!showHome && canShowPrev"
                            }
                          ],
                          staticClass: "controller-button prev-button",
                          on: { click: _vm.prevLesson }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "8",
                                height: "15",
                                fill:
                                  "var(--stan-store-custom-highlight-color)",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d:
                                    "M7.438.238a.812.812 0 010 1.149L1.704 7.121a.27.27 0 000 .383l5.734 5.734a.812.812 0 11-1.149 1.149L.555 8.653c-.74-.74-.74-1.94 0-2.681L6.29.238a.812.812 0 011.15 0z",
                                  fill: "inherit"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "controller-button-text align-items-start flex-shrink-full"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "para-3 button-header-text" },
                                [_vm._v("Previous Lesson:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "para-3 font-semibold button-subheader-text text-ellipsis"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.previousLessonTitle) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showHome && _vm.canShowNext,
                              expression: "!showHome && canShowNext"
                            }
                          ],
                          staticClass: "controller-button next-button",
                          on: { click: _vm.nextLesson }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "controller-button-text align-items-end flex-shrink-full"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "para-3 button-header-text" },
                                [_vm._v("Next Lesson:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "para-3 font-semibold button-subheader-text text-ellipsis text-right"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.nextLessonTitle) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "8",
                                height: "16",
                                fill:
                                  "var(--stan-store-custom-highlight-color)",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d:
                                    "M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z",
                                  fill: "inherit"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showHome && !_vm.canShowNext,
                              expression: "!showHome && !canShowNext"
                            }
                          ],
                          staticClass: "controller-button next-button",
                          on: {
                            click: function($event) {
                              return _vm.goToHome()
                            }
                          }
                        },
                        [_vm._m(0)]
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("CourseSidebar", {
        attrs: {
          modules: _vm.modules,
          showCoursesList: _vm.showCoursesList,
          course: _vm.course,
          activeModule: _vm.currentModule,
          activeLesson: _vm.currentLesson,
          chooseLesson: _vm.goToLesson
        },
        on: {
          chooseHome: _vm.goToHome,
          toggleShowCoursesList: _vm.toggleShowCoursesList
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "controller-button-text align-items-end" },
      [
        _c("div", { staticClass: "para-3 button-header-text" }, [
          _vm._v("All Done!")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "para-3 font-semibold button-subheader-text text-ellipsis text-right"
          },
          [_vm._v("\n\t\t\t\t\t\t\tComplete Course\n\t\t\t\t\t\t")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }