<template>
	<div>
		<div class="price-input-section">
			<div class="price-wrapper" :class="{ 'form-error': validation.amount.$error }">
				<AppInput
					:label="`${$t('Price')}(${getCurrencySymbol(currency)}) *`"
					input-class="mb-1"
					placeholder=""
					v-model.number="value.amount"
					type="number"
					@blur="validation.$touch"
					hasPrecision
				/>
				<small class="stan-text-danger error">
					{{ price_validation_error_message }}
				</small>
			</div>

			<div class="price-wrapper" :class="{ 'form-error': validation.sale_amount.$error }">
				<div>
					<label class="control-label">
						<span class="mr-1">{{ $t('Discount Price') }}({{ getCurrencySymbol(currency) }})</span>
						<AppToggle :val="value.sale_amount_available" @valueChange="toggleDiscountAvailable" />
					</label>
				</div>
				<AppInput
					input-class="mb-1"
					v-model.number="value.sale_amount"
					:disabled="!value.sale_amount_available"
					type="number"
					@blur="validation.$touch"
					hasPrecision
				/>
				<small class="stan-text-danger error"> {{ price_validation_error_message }}</small>
			</div>
		</div>

		<div class=" mb-3" v-if="recurring">
			<div>
				<label class="control-label text-truncate mb-0">{{ $t('Scheduling') }}</label>
				<div class="text-sm">{{ $t('Customize the duration and billing cycle of your membership') }}</div>
			</div>
		</div>

		<div class="price-input-section">
			<div class="price-wrapper mb-3" v-if="recurring" :class="{ 'form-error': validation.interval.$error }">
				<div>
					<label class="control-label mr-1">{{ $t('Recurring *') }}</label>
				</div>
				<AppDropdown
					:searchable="false"
					:options="[
						{ label: 'Yearly', value: 'year' },
						{ label: 'Monthly', value: 'month' },
						{ label: 'Weekly', value: 'week' },
						{ label: 'Daily', value: 'day' },
					]"
					reduce="value"
					v-model="value.interval"
					@blur="validation.interval.$touch"
					class="form-control"
				/>
			</div>

			<div class="price-wrapper mb-3" v-if="recurring && !hideCancelSubscription">
				<div>
					<label class="control-label">
						<span class="mr-1">{{ $t('Cancel subscription after') }}</span>
						<AppToggle :val="value.membership_duration_available" @valueChange="toggleDurationAvailable" />
					</label>
				</div>
				<AppDropdown
					:searchable="false"
					:options="durationOptions"
					reduce="value"
					v-model="value.membership_duration"
					class="form-control h-auto px-2"
					@blur="validation.$touch"
					:disabled="!value.membership_duration_available"
				/>
			</div>
		</div>
	</div>
</template>
<script>
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'PriceForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			currency: {
				type: String,
				default: 'USD',
			},
			recurring: Boolean,
			validation: {
				type: Object,
				default: () => {},
			},
			hideCancelSubscription: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			price_validation_error_message() {
				const fiftyCents = this.formatCurrency(0.5, this.currency, 'en-US', true)
				const twentyFiveThousand = this.formatCurrency(25000, this.currency, 'en-US', true)
				const zero = this.formatCurrency(0, this.currency, 'en-US', true)

				return `Must be between ${fiftyCents} and ${twentyFiveThousand} or ${zero}`
			},
			durationOptions() {
				const options = [
					{
						label: `N/A (ongoing payments)`,
						value: 0,
					},
				]
				for (let i = 1; i <= 24; i++) {
					options.push({ label: `${i} ${this.value.interval}${i === 1 ? '' : 's'}`, value: i })
				}
				return options
			},
		},
		mounted() {
			if (!('membership_duration' in this.value)) {
				this.$set(this.value, 'membership_duration', 0)
			}
		},
		methods: {
			getCurrencySymbol: commonHelper.getCurrencySymbol,
			formatCurrency: commonHelper.formatCurrency,
			toggleDiscountAvailable() {
				if (!('sale_amount_available' in this.value)) {
					this.$set(this.value, 'sale_amount_available', false)
				}
				this.value.sale_amount_available = !this.value.sale_amount_available
			},
			toggleDurationAvailable() {
				if (!('membership_duration_available' in this.value)) {
					this.$set(this.value, 'membership_duration_available', false)
				}
				this.value.membership_duration_available = !this.value.membership_duration_available
				if (!this.value.membership_duration_available) {
					this.value.membership_duration = 0
				} else if (this.value.membership_duration_available && !this.value.membership_duration) {
					this.value.membership_duration = 3
				}
			},
		},
		directives: {
			'two-dp': {
				bind: function twoDp(el) {
					el.addEventListener('keyup', e => {
						// evolved from https://stackoverflow.com/a/52685887/9614402
						const keyCode = e.keyCode ? e.keyCode : e.which
						const value = e.target.value
						const i = value.indexOf('.')

						if ((keyCode < 48 || keyCode > 57) && !(keyCode === 46 && i === -1)) {
							// 46 is dot
							// only allow numbers and one dot
							e.preventDefault()
						} else {
							const splits = e.target.value.split('.')
							if (splits.length > 1 && splits[1].length > 1) {
								e.target.value = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
								e.target.dispatchEvent(new Event('input'))
							}
						}
					})
					// modify the initial value if it's not 2dp
					const inputElement = el.nodeName === 'INPUT' ? el : el.querySelector('input')
					const stringValue = inputElement.value
					const splits = stringValue.split('.')
					if (splits.length > 1 && splits[1]?.length > 2) {
						const newValue = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
						if (inputElement.value !== newValue) {
							inputElement.value = newValue
							inputElement.dispatchEvent(new Event('input'))
						}
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.price-input-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
		align-items: center;
		@media (max-width: 575px) {
			grid-template-columns: auto;
		}
		.error {
			opacity: 0;
			display: block;
			font-size: 70%;
			@media (max-width: 767px) {
				font-size: 10px;
			}
		}
		.form-error {
			.error {
				opacity: 1;
			}
		}
		.price-wrapper {
			display: flex;
			flex-direction: column;
		}
	}
</style>
