const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)
const moduleRoutes = []
const modules = {
	path: '/widgets',
	name: 'WidgetsIndex',
	redirect: '/widgets',
	component: MainContainer,
	props: true,
	children: [
		{
			path: '',
			name: 'widgets',
			component: () => import('./Widgets').then(m => m.default || m),
			meta: {
				pageTitle: 'Widgets',
				breadcrumbs: [{ title: 'Widgets', active: true }],
			},
		},
	],
}

export default moduleRoutes.concat(modules)
