import axios from 'axios'

/* eslint-disable import/extensions */

export const state = {
	isAutomationsLoading: false,
	automations: [],
	instagramBusinessAccounts: [],
	instagramPostsMetadata: [],
}

export const getters = {
	isAutomationsLoading: state => state.isAutomationsLoading,
	getAutomations: state => state.automations,
	getInstagramBusinessAccounts: state => state.instagramBusinessAccounts,
	getInstagramPostsMetadata: state => state.instagramPostsMetadata,
}

export const mutations = {
	setAutomations(state, automations) {
		state.automations = automations
	},
	setAutomationLoading(state, isLoading) {
		state.isAutomationsLoading = isLoading
	},
	setInstagramBusinessAccounts(state, accounts) {
		state.instagramBusinessAccounts = accounts
	},
	setInstagramPostsMetadata(state, metadata) {
		state.instagramPostsMetadata = metadata
	},
	setAutomationAnalytics(state, analytics) {
		for (const automation of state.automations) {
			automation.analytics = { ...automation.analytics, ...analytics[`${automation.automation_id}`] }
		}
	},
}

export const actions = {
	fetchAutomations: async ({ commit }) => {
		commit('setAutomationLoading', true)

		try {
			const res = await axios.get('v1/automations')

			const automations = res.data.automations ?? []
			const instagramAccounts = res.data.instagram_business_accounts ?? []

			commit('setAutomations', automations)
			commit('setInstagramBusinessAccounts', instagramAccounts)

			return automations
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
			commit('setAutomations', [])
			return []
		} finally {
			commit('setAutomationLoading', false)
		}
	},
	fetchInstagramPosts: ({ commit }) => {
		axios
			.get('v1/automations/instagram-posts-metadata')
			.then(res => {
				commit('setInstagramPostsMetadata', res.data)
			})
			.catch(() => {
				commit('setInstagramPostsMetadata', [])
			})
	},
	fetchAutomationAnalytics: async ({ state, commit }) => {
		const automationIds = state.automations.map(a => a.automation_id).filter(id => id)

		if (automationIds.length > 0) {
			try {
				const res = await axios.get('/v1/automations/analytics', {
					params: {
						automation_ids: automationIds.join(','),
					},
				})

				const analytics = res.data.analytics ?? {}
				commit('setAutomationAnalytics', analytics)
				return analytics
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
				return null
			} finally {
				commit('setAutomationLoading', false)
			}
		}
		return null
	},
}
