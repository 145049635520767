<template>
	<section class="stan-section">
		<div>
			<div class="stan-heading gap" :class="{ 'stan-margin-bottom': !subtext || subtextPlacement !== 'bottom' }">
				<div v-if="number" class="para-2 text-bold number">{{ number }}</div>
				<div class="para-1 text-bold title">
					<div>
						<span :class="{ error: hasError }">{{ title }}</span>
						<span class="para-3 text-light" v-if="subtext && subtextPlacement === 'right'">{{ subtext }}</span>
					</div>
				</div>
			</div>
			<p class="para-3 text-light stan-margin-bottom header-offset" v-if="subtext && subtextPlacement === 'bottom'">{{ subtext }}</p>
		</div>
		<slot></slot>
	</section>
</template>
<script>
	export default {
		name: 'AppSection',
		props: {
			number: { type: [Number, String], default: 0 },
			title: { type: String, default: '' },
			subtext: { type: String, default: '' },
			subtextPlacement: {
				type: String,
				default: 'right',
				validator(value) {
					return ['bottom', 'right'].includes(value.toLowerCase())
				},
			},
			hasError: { type: Boolean, default: false },
		},
	}
</script>
<style lang="scss" scoped>
	.stan-section {
		&:not(:last-child) {
			padding-bottom: 60px;
		}
		.error {
			color: var(--stan-danger);
		}
		.stan-heading {
			display: flex;
			align-items: center;
			.number {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: var(--stan-gray-primary-color);
				width: 30px;
				height: 30px;
				text-align: center;
			}
			.title {
				font-size: 1rem;
				letter-spacing: -0.3px;
			}

			p {
				margin: 0;
			}
		}

		&.section-error {
			.stan-heading {
				color: var(--stan-danger);
				.title {
					color: var(--stan-danger);
				}
			}
		}
	}

	.stan-margin-bottom {
		margin-bottom: 1.5rem;
	}

	.header-offset {
		padding-left: 45px;
	}
</style>
