<template>
	<div class="stan-device preview-type-store">
		<div
			v-if="loading || !getStore"
			v-bind:class="{ 'funnel-site-preview-section': funnelClass, 'site-preview-section': !funnelClass }"
			class="mx-auto store-theme"
		>
			<CalendarSkeleton class="mb-5" :heading="false" />
			<Skeleton class="mb-5" />
			<Skeleton class="mb-5" />
			<Skeleton class="mb-5" />
			<Skeleton class="mb-5" />
		</div>
		<div
			v-else
			v-bind:class="{ 'funnel-site-preview-section': funnelClass, 'site-preview-section': !funnelClass }"
			class=" mx-auto store-theme"
			ref="device-wrapper"
		>
			<div class="site__prev__masking store-theme" :class="storeTheme">
				<PreviewSkeleton v-if="funnelSkeleton === true" />
				<div v-else class="store-layout">
					<div id="fixed-background" class="d-none" />
					<Header
						:full_name="user.full_name"
						:username="user.username"
						:userBio="user.bio"
						:user_image="user.profile_image"
						:storeTheme="storeTheme"
						:socialIcons="user.data.socials"
						class="mb-3"
					/>
					<div v-if="sections" class="store-content">
						<div class="section" v-for="(section, index) in sections" :key="index">
							<div class="section-title" v-if="section.data['title']">{{ section.data['title'] }}</div>
							<div class="w-100">
								<TransitionGroup name="fade">
									<div v-for="block in section.pages" :key="block.page_id" class="mb-3 default-cursor preview-button">
										<div v-if="block.data && block.data.button && block.status === 2">
											<MediaPreview
												v-if="isEmbed(block.data.button.layout)"
												:embedUrl="block.data.product.link.embed_url"
												:embedStyle="block.data.product.link.embed_style"
												:embedType="block.data.product.link.embed_type"
											/>
											<LeadMagnetButton
												v-else-if="block.data.product.type == 'lead-magnet'"
												:user="user"
												:title="block.data.button.title"
												:heading="block.data.button.heading"
												:image="block.data.button.image"
												:tagline="block.data.button.tagline"
												:questions="questionsToForm(block)"
												:disabled="true"
												ref="form"
												style="pointer-events: none;"
											/>
											<SitePreviewButton
												v-else-if="isLayoutPreview(block.data.button.layout)"
												:user="user"
												:title="block.data.button.title"
												:heading="block.data.button.heading"
												:image="block.data.button.image"
												:tagline="block.data.button.tagline"
												:reviews="block.data.product.reviews"
												:product-type="block.data.product.type"
												:price="getPrice(block.data.product)"
												:quantity="productQuantity(block.data.product)"
												ref="form"
												:page-id="block.page_id"
												:product="block.data.product"
												:isDemo="true"
											/>
											<SiteCalloutButton
												v-else-if="isLayoutCallout(block.data.button.layout)"
												:user="user"
												:title="block.data.button.title"
												:heading="block.data.button.heading"
												:image="block.data.button.image"
												:tagline="block.data.button.tagline"
												:reviews="block.data.product.reviews"
												:product-type="block.data.product.type"
												:price="getPrice(block.data.product)"
												:quantity="productQuantity(block.data.product)"
												ref="form"
												:page-id="block.page_id"
												:product="block.data.product"
												:isDemo="true"
											/>
											<SiteButton
												v-else-if="isLayoutButton(block.data.button.layout)"
												:user="user"
												:title="block.data.button.title"
												:image="block.data.button.image"
												:reviews="block.data.product.reviews"
												:product-type="block.data.product.type"
												:price="getPrice(block.data.product)"
												:quantity="productQuantity(block.data.product)"
											/>
										</div>
									</div>
								</TransitionGroup>
							</div>
						</div>
					</div>
					<div v-else-if="blocks" class="store-content">
						<div v-for="block in blocks" :key="block.page_id" class="mb-3 default-cursor preview-button">
							<div v-if="block.data && block.data.button && block.status === 2">
								<MediaPreview
									v-if="isEmbed(block.data.button.layout)"
									:embedUrl="block.data.product.link.embed_url"
									:embedStyle="block.data.product.link.embed_style"
									:embedType="block.data.product.link.embed_type"
								/>
								<LeadMagnetButton
									v-else-if="block.data.product.type == 'lead-magnet'"
									:user="user"
									:title="block.data.button.title"
									:heading="block.data.button.heading"
									:image="block.data.button.image"
									:tagline="block.data.button.tagline"
									:questions="questionsToForm(block)"
									:disabled="true"
									ref="form"
									style="pointer-events: none;"
								/>
								<SitePreviewButton
									v-else-if="isLayoutPreview(block.data.button.layout)"
									:user="user"
									:title="block.data.button.title"
									:heading="block.data.button.heading"
									:image="block.data.button.image"
									:tagline="block.data.button.tagline"
									:reviews="block.data.product.reviews"
									:product-type="block.data.product.type"
									:price="getPrice(block.data.product)"
									:quantity="productQuantity(block.data.product)"
									ref="form"
									:page-id="block.page_id"
									:product="block.data.product"
									:isDemo="true"
								/>
								<SiteCalloutButton
									v-else-if="isLayoutCallout(block.data.button.layout)"
									:user="user"
									:title="block.data.button.title"
									:heading="block.data.button.heading"
									:image="block.data.button.image"
									:tagline="block.data.button.tagline"
									:reviews="block.data.product.reviews"
									:product-type="block.data.product.type"
									:price="getPrice(block.data.product)"
									:quantity="productQuantity(block.data.product)"
									ref="form"
									:page-id="block.page_id"
									:product="block.data.product"
									:isDemo="true"
								/>
								<SiteButton
									v-else-if="isLayoutButton(block.data.button.layout)"
									:user="user"
									:title="block.data.button.title"
									:image="block.data.button.image"
									:reviews="block.data.product.reviews"
									:product-type="block.data.product.type"
									:price="getPrice(block.data.product)"
									:quantity="productQuantity(block.data.product)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { debounce as _debounce } from 'lodash'
	import LeadMagnetButton from '~/stan-vue-shared/components/buttons/LeadMagnetButton'
	import SitePreviewButton from '~/stan-vue-shared/components/buttons/SitePreviewButton'
	import SiteCalloutButton from '~/stan-vue-shared/components/buttons/SiteCalloutButton'
	import SiteButton from '~/stan-vue-shared/components/buttons/SiteButton'
	import Header from '~/stan-vue-shared/components/Header'
	import Skeleton from '~/components/skeletons/Skeleton'
	import CalendarSkeleton from '~/components/skeletons/CalendarSkeleton'
	import MediaPreview from '~/stan-vue-shared/components/MediaPreview'
	import QuickSetupPreviewSkeleton from './QuickSetupPreviewSkeleton'

	export default {
		components: {
			LeadMagnetButton,
			SitePreviewButton,
			SiteCalloutButton,
			SiteButton,
			PreviewSkeleton: QuickSetupPreviewSkeleton,
			Skeleton,
			CalendarSkeleton,
			Header,
			MediaPreview,
		},
		props: {
			user: { type: Object },
			page_name: { type: String, default: '' },
			blocks: { type: Array },
			sections: { type: Array },
			funnelSkeleton: { type: Boolean, default: false },
			funnelClass: { type: Boolean, default: false },
			loading: { type: Boolean, default: false },
			features: { type: Object },
		},
		data() {
			return {
				showAnimation: false,
				socialIcons: {},
			}
		},
		name: 'StorePreview',
		computed: {
			...mapGetters('Stores', ['getStore', 'getStoreTheme', 'getPreviewTheme']),
			storeTheme() {
				return this.getPreviewTheme ?? this.getStoreTheme ?? 'default'
			},
		},
		mounted() {
			if (!localStorage.getItem(`tool-tip-${this.user ? this.user.user_id : ''}`)) {
				this.showAnimation = true
			}
			this.onWindowResize()
			window.addEventListener('resize', this.onWindowResize)
		},
		unmounted() {
			window.removeEventListener('resize', this.onWindowResize)
		},
		methods: {
			// eslint-disable-next-line prefer-arrow-callback, func-names
			onWindowResize: _debounce(function() {
				const documentStyles = document.documentElement.style
				const previewWidth = $('.stan-device.preview-type-store').width() ?? 475
				documentStyles.setProperty('--preview-store-zoom', previewWidth / 475)
			}, 500),
			isLayoutPreview(layout) {
				return layout?.toLowerCase() === 'preview'
			},
			isLayoutCallout(layout) {
				return layout?.toLowerCase() === 'callout'
			},
			isLayoutButton(layout) {
				return layout?.toLowerCase() === 'button'
			},
			isEmbed(layout) {
				return layout?.toLowerCase() === 'embed'
			},
			questionsToForm(block) {
				let questions = block.data.product.questions || block.data.button.questions
				if (block.data.button.questions) {
					questions = [{ required: true, content: this.$t('Phone Number'), type: 'phone' }, ...questions]
				}
				return questions
			},
			getButtonConfig(block) {
				return {
					bloc_title: block.data.button.heading,
					bloc_description: block.data.button.tagline,
					button_title: block.data.button.title,
				}
			},
			getPrice(product) {
				return product?.price ?? 0
			},
			productQuantity(product) {
				return product?.inventory?.manage ? product.inventory.stock : undefined
			},
			calculateColors() {
				document.documentElement.style.removeProperty('--stan-store-primary-color')
				document.documentElement.style.removeProperty('--stan-store-secondary-color')
				document.documentElement.style.removeProperty('--stan-store-primary-font')
				document.documentElement.style.setProperty('--stan-store-background-url', `url(${this.user.profile_image})`)
				if (this.store?.data?.primary_color) {
					this.$commonHelper.addThemeColorsToDocument(
						this.store?.data?.theme,
						this.store?.data?.primary_color,
						this.store?.data?.secondary_color,
						this.store?.data?.primary_font
					)
				}
			},
		},
		watch: {
			getStore(store) {
				this.store = store
				this.calculateColors()
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import 'src/stan-vue-shared/assets/styles/themes/index.scss';

	.store-theme.default .block--lead-magnet {
		z-index: 0 !important;
	}
	.device-user_animation {
		animation: 3s running Header;
		transform-box: fill-box;
		transform-origin: top;
		transition-delay: 3s;
	}
	.funnel-site-preview-section {
		padding-bottom: 20px;
	}
	.preview-button {
		width: 100%;
	}
</style>
