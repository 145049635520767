<template>
	<div>
		<div class="form-group d-flex align-items-start justify-content-between d-flex heading-wrapper mb-2">
			<div class="digital-text-content col-sm p-0">
				<label v-if="customSubheading" class="m-0 subtitle">{{ customSubheading }}</label>
				<label v-else class="m-0 subtitle">{{ `${contentTypeName} will be sent automatically to customer after purchase` }}</label>
			</div>
			<AppButtonGroup
				v-if="showTypeChooser"
				:options="uploadFileOptions"
				:initialValue="digital_assets_type"
				@selected="toggleAssetType"
				:disabled="this.$refs['file-drop-zone'] && !this.$refs['file-drop-zone'].allFilesUploaded"
			/>
		</div>

		<div
			class="d-flex flex-column gap-15px"
			:style="{
				display: this.digital_assets_type === 'file' ? 'block' : 'none !important',
			}"
		>
			<FileDropZone
				:userflowKey="fileDropZoneUserflowKey"
				ref="file-drop-zone"
				@start-upload="payload => $emit('start-upload', payload)"
				@uploaded-all="payload => $emit('uploaded-all', payload)"
				@uploaded="fileUploaded"
				@failed="fileUploadedFailed"
				@progress="onUploadProgress"
				@delete-file="deleteFile"
				@cancel-file="cancelFile"
				data-test-id="upload-button-file-button"
				:show-button-image="false"
				button-text="Upload"
				accept="file"
				:multiple="true"
				:objectType="objectType"
			/>
			<UploadedItemRow
				v-for="(digital_asset, index) in this.existingDigitalAssets"
				:key="digital_asset.url"
				:isUploading="fileStatus == 0"
				:uploadProgress="uploadFileProgress"
				:fileName="digital_asset.name"
				:isErrored="fileStatus == 2"
				@deleteFile="() => removeDigitalFile(index)"
				:url="digital_asset.url"
			/>
		</div>
		<!-- Digital_assets: url -->
		<div v-show="this.digital_assets_type === 'url'">
			<AppInput
				class="form-group"
				icon="/images/icons/ic-link.svg"
				placeholder="https://..."
				v-model.trim="redirectUrl"
				@input="uploadUrl"
				@blur="$v.fileData.digital_assets.$each.$touch"
				:error="errorText"
			/>
			<AppInput
				icon="/images/icons/ic-edit.svg"
				:placeholder="linkPlaceholder"
				v-model.trim="redirectUrlName"
				@input="uploadUrl"
				@blur="$v.fileData.digital_assets.$each.$touch"
			/>
		</div>
	</div>
</template>
<script>
	// eslint-disable-next-line import/extensions
	import { url, requiredIf } from 'vuelidate/lib/validators'
	import Vue from 'vue'
	import VueEllipseProgress from 'vue-ellipse-progress'
	import FileDropZone from '~/components/uploads/FileDropZone'

	Vue.use(VueEllipseProgress)
	export default {
		components: {
			FileDropZone,
		},
		props: {
			isRequired: { type: Boolean, default: false },
			block_id: { type: Number },
			showTypeChooser: { type: Boolean, default: true },
			fileData: {
				digital_assets_type: { type: String, default: 'file' },
				digital_assets: {
					type: Array,
				},
			},
			customSubheading: String,
			objectType: { type: String, default: 'unknown' },
			userflowKey: {
				type: String,
				default: '',
			},
		},
		computed: {
			linkPlaceholder() {
				return `Your Product's Name (ex: "Course 101")`
			},
			contentTypeName() {
				return this.digital_assets_type === 'file' ? this.$t('File(s)') : this.$t('URL')
			},
			errorText() {
				if (this.$v.fileData.digital_assets.$each.$iter[0] && this.$v.fileData.digital_assets.$each.$iter[0].$error) {
					if (!this.$v.fileData.digital_assets.$each.$iter[0].url.url) {
						return [this.$t('Please include http:// or https://')]
					}
					if (!this.$v.fileData.digital_assets.$each.$iter[0].url.required) {
						return [this.$t('URL is required')]
					}
					if (!this.$v.fileData.digital_assets.$each.$iter[0].url.forbiddenSites) {
						return [this.$t('Sorry, redirect to this site is not allowed on Stan')]
					}
				}
				return []
			},
			fileDropZoneUserflowKey() {
				return `${this.userflowKey}-filedropzone`
			},
			existingDigitalAssets() {
				return this.fileData.digital_assets.filter(f => this.newDigitalAssets.indexOf(f) < 0)
			},
		},
		validations() {
			return {
				fileData: {
					digital_assets: {
						$each: {
							url: {
								// eslint-disable-next-line func-names
								required: requiredIf(function(model) {
									return this.isRequired || (model?.name && model?.name.trim().length > 0)
								}),
								url,
								forbiddenSites: value =>
									!['onlyfans', 'chaturbate.com', 'solchicks.io', 'pornhub.com'].some(item => value.indexOf(item) !== -1),
							},
						},
					},
				},
			}
		},
		mounted() {
			this.digital_assets_type = this.fileData.digital_assets_type ? this.fileData.digital_assets_type : 'file'
			this.uploadFileFlag = 1
			this.fileStatus = 1

			if (this.fileData.digital_assets_type === 'url') {
				this.redirectUrl = this.fileData.digital_assets[0]?.url
				this.redirectUrlName = this.fileData.digital_assets[0]?.name
			}
		},
		data() {
			return {
				previousFile: null,
				previousUrl: null,
				redirectUrl: '',
				redirectUrlName: '',
				uploadFileData: null,
				uploadFileFlag: 0,
				uploadFileProgress: 0,
				uploadFileName: '',
				fileStatus: 0,
				progress: 0,
				digital_assets_type: 'file',
				selected: 'selected',
				newDigitalAssets: [],
				tempFileData: {
					files: [],
					urls: [],
				},
				uploadFileOptions: [
					{
						name: 'File',
						value: 'file',
						label: 'Upload File',
						mobileLabel: 'File',
					},
					{
						name: 'URL',
						value: 'url',
						label: 'Redirect to URL',
						mobileLabel: 'URL',
					},
				],
				fileDropZoneTick: 0, // added to ensure cancelling an upload doesn't block it from being uploaded again
			}
		},
		created() {},
		methods: {
			fileUploaded(fileData) {
				this.uploadFileFlag = 1

				if (this.uploadFileProgress === 100) {
					this.fileStatus = 1
				}

				const newAssetsType = fileData.name === 'URL' ? 'url' : 'file'

				if (this.fileData.digital_assets_type !== newAssetsType || newAssetsType === 'url') {
					this.fileData.digital_assets = []
				}
				this.fileData.digital_assets_type = newAssetsType
				const newDigitalAsset = {
					url: fileData.src,
					name: fileData.name,
				}
				this.fileData.digital_assets.push(newDigitalAsset)
				this.newDigitalAssets.push(newDigitalAsset)

				const uploadPayload = {
					pageFileData: this.fileData,
					uploadedAsset: newDigitalAsset,
				}
				this.$emit('uploaded', uploadPayload)
			},
			fileUploadedFailed() {
				this.$emit('uploadFailed', this.fileData)
			},
			onUploadProgress(payload) {
				this.uploadFileProgress = payload.progress
			},
			uploadUrl() {
				this.fileData.digital_assets_type = 'url'
				this.fileData.digital_assets = []
				if (this.redirectUrlName || (this.redirectUrl && this.redirectUrl.trim().length > 0)) {
					this.fileData.digital_assets = [
						{
							name: this.redirectUrlName ? this.redirectUrlName : this.$t('Your Download'),
							url: this.redirectUrl,
						},
					]
				}
			},
			deleteFile(payload) {
				this.fileData.name = ''
				this.fileData.url = ''
				this.$emit('delete-file', payload)
			},
			cancelFile(payload) {
				this.fileData.digital_assets = this.fileData.digital_assets.filter(a => a.url !== payload.src)
				this.$nextTick(() => this.$emit('cancel-file', payload))
			},
			removeDigitalFile(index) {
				this.fileData.digital_assets.splice(index, 1)
				if (this.fileData.digital_assets.length === 0) {
					this.redirectUrl = undefined
					this.redirectUrlName = undefined
				}
				this.$emit('cancel-file', this.fileData)
			},
			toggleAssetType(typeName) {
				if (this.$refs['file-drop-zone'] && !this.$refs['file-drop-zone'].allFilesUploaded) return

				this.digital_assets_type = typeName
				this.fileData.digital_assets_type = typeName
				if (typeName === 'file') {
					this.tempFileData.urls = [...this.fileData.digital_assets]
					this.fileData.digital_assets = this.tempFileData.files
				} else if (typeName === 'url') {
					this.tempFileData.files = [...this.fileData.digital_assets]
					this.fileData.digital_assets = this.tempFileData.urls
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.new::v-deep {
		.common-file-drop-zone {
			background-color: var(--stan-white);
			border: 1px dashed var(--stan-gray-strike-color);
		}
	}
	.heading-wrapper {
		gap: 30px;
	}

	.digital-text-content {
		width: calc(100% - 50px);
		min-width: 0;
	}

	.upload-url-section {
		display: grid;
		grid-template-columns: 123px auto;
		width: 100%;
		grid-column-gap: 8px;
		position: relative;
		.link-image {
			padding: 0 15px 0 15px;
			img {
				width: 93px;
				height: 98px;
			}
		}
		.url-field-wrap {
			padding-left: 0;
			.url-input-action {
				align-items: center;

				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 22px;
				letter-spacing: 0.04em;

				color: #3a36db;
			}
		}
	}

	.upload-url-sections-wrapper {
		min-height: 120px;
		border: 1px solid var(--stan-boulder-3);
		box-sizing: border-box;
		box-shadow: 0 1px 10px -2px rgba(62, 98, 134, 0.1);
		border-radius: 10px;
		background: white;
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 80%;
	}

	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.block-error {
		label {
			color: var(--stan-danger);
		}
		input {
			border: 1px solid var(--stan-danger);
		}
		input:focus {
			outline: none !important;
		}
	}

	.progress-bar-wrapper {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: var(--stan-primary-dark-color);
		.file-name {
			i {
				font-size: 24px;
				color: var(--stan-primary-primary-color);
			}
		}
		.circle-progress-bar {
			.text-green {
				color: var(--stan-persian-green-3);
			}
			.text-red {
				color: var(--stan-danger);
			}
			.div-hide {
				display: none;
			}
			.legend-custom-style {
				left: -45px !important;
			}
			.cursor-pointer {
				cursor: pointer;
			}
		}
	}

	.filelist-icons::v-deep {
		height: 18px;
		width: 18px;

		svg {
			transform: scale(0.75);
			path {
				fill: var(--stan-gray-dark-color, #bbbbbb);
			}
		}
	}

	.image {
		max-width: 32px;
		max-height: 32px;
	}
</style>
