var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "form-group d-flex align-items-start justify-content-between d-flex heading-wrapper mb-2"
      },
      [
        _c("div", { staticClass: "digital-text-content col-sm p-0" }, [
          _vm.customSubheading
            ? _c("label", { staticClass: "m-0 subtitle" }, [
                _vm._v(_vm._s(_vm.customSubheading))
              ])
            : _c("label", { staticClass: "m-0 subtitle" }, [
                _vm._v(
                  _vm._s(
                    `${_vm.contentTypeName} will be sent automatically to customer after purchase`
                  )
                )
              ])
        ]),
        _vm._v(" "),
        _vm.showTypeChooser
          ? _c("AppButtonGroup", {
              attrs: {
                options: _vm.uploadFileOptions,
                initialValue: _vm.digital_assets_type,
                disabled:
                  this.$refs["file-drop-zone"] &&
                  !this.$refs["file-drop-zone"].allFilesUploaded
              },
              on: { selected: _vm.toggleAssetType }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex flex-column gap-15px",
        style: {
          display:
            this.digital_assets_type === "file" ? "block" : "none !important"
        }
      },
      [
        _c("FileDropZone", {
          ref: "file-drop-zone",
          attrs: {
            userflowKey: _vm.fileDropZoneUserflowKey,
            "data-test-id": "upload-button-file-button",
            "show-button-image": false,
            "button-text": "Upload",
            accept: "file",
            multiple: true,
            objectType: _vm.objectType
          },
          on: {
            "start-upload": payload => _vm.$emit("start-upload", payload),
            "uploaded-all": payload => _vm.$emit("uploaded-all", payload),
            uploaded: _vm.fileUploaded,
            failed: _vm.fileUploadedFailed,
            progress: _vm.onUploadProgress,
            "delete-file": _vm.deleteFile,
            "cancel-file": _vm.cancelFile
          }
        }),
        _vm._v(" "),
        _vm._l(this.existingDigitalAssets, function(digital_asset, index) {
          return _c("UploadedItemRow", {
            key: digital_asset.url,
            attrs: {
              isUploading: _vm.fileStatus == 0,
              uploadProgress: _vm.uploadFileProgress,
              fileName: digital_asset.name,
              isErrored: _vm.fileStatus == 2,
              url: digital_asset.url
            },
            on: { deleteFile: () => _vm.removeDigitalFile(index) }
          })
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.digital_assets_type === "url",
            expression: "this.digital_assets_type === 'url'"
          }
        ]
      },
      [
        _c("AppInput", {
          staticClass: "form-group",
          attrs: {
            icon: "/images/icons/ic-link.svg",
            placeholder: "https://...",
            error: _vm.errorText
          },
          on: {
            input: _vm.uploadUrl,
            blur: _vm.$v.fileData.digital_assets.$each.$touch
          },
          model: {
            value: _vm.redirectUrl,
            callback: function($$v) {
              _vm.redirectUrl = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "redirectUrl"
          }
        }),
        _vm._v(" "),
        _c("AppInput", {
          attrs: {
            icon: "/images/icons/ic-edit.svg",
            placeholder: _vm.linkPlaceholder
          },
          on: {
            input: _vm.uploadUrl,
            blur: _vm.$v.fileData.digital_assets.$each.$touch
          },
          model: {
            value: _vm.redirectUrlName,
            callback: function($$v) {
              _vm.redirectUrlName = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "redirectUrlName"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }