var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "left-icon": _vm.icon,
        "right-icon": _vm.isTypePassword,
        "error-wrap": _vm.hasError
      }
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between gap" }, [
        _c("div", { staticClass: "col px-0" }, [
          _vm.label
            ? _c(
                "label",
                { staticClass: "control-label", attrs: { for: _vm.id } },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.maxlength != undefined &&
        _vm.isTypeText &&
        _vm.showCharacterCount &&
        !_vm.useInlineMaxLength
          ? _c("span", { staticClass: "character-counter para-3" }, [
              _vm._v(
                _vm._s(_vm.value ? _vm.value.length : 0) +
                  "/" +
                  _vm._s(_vm.maxlength)
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center flex-wrap para-2" }, [
        _vm.prefix
          ? _c("label", { staticClass: "mb-0 pr-1 text-truncate" }, [
              _vm._v(_vm._s(_vm.prefix))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col px-0" },
          [
            _vm.icon
              ? _c("InlineSvg", {
                  staticClass: "input-icon",
                  attrs: { src: _vm.icon, width: _vm.iconWidth }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: {
                  suffixed: _vm.suffix || _vm.useInlineMaxLength,
                  "form-control": _vm.suffix || _vm.useInlineMaxLength,
                  error: _vm.hasError,
                  primary: _vm.primary,
                  disabled: _vm.disabled
                }
              },
              [
                _vm.typeUse === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.localValue,
                          expression: "localValue"
                        },
                        {
                          name: "two-dp",
                          rawName: "v-two-dp",
                          value: _vm.hasPrecision && _vm.isTypeNumber,
                          expression: "hasPrecision && isTypeNumber"
                        }
                      ],
                      ref: "inputElement",
                      class: [
                        _vm.inputClass,
                        {
                          "form-control":
                            !_vm.suffix && !_vm.useInlineMaxLength,
                          "text-lowercase": _vm.lowercase,
                          "text-uppercase": _vm.uppercase,
                          error: _vm.hasError,
                          primary: _vm.primary
                        }
                      ],
                      attrs: {
                        id: _vm.id,
                        name: _vm.name,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                        maxlength: _vm.maxlength,
                        max: _vm.max,
                        min: _vm.min,
                        autocomplete: _vm.disableAutoComplete
                          ? "disable-autocomplete"
                          : "on",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.localValue)
                          ? _vm._i(_vm.localValue, null) > -1
                          : _vm.localValue
                      },
                      on: {
                        keydown: e => _vm.$emit("keydown", e),
                        keyup: e => _vm.$emit("keyup", e),
                        change: [
                          function($event) {
                            var $$a = _vm.localValue,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.localValue = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.localValue = $$c
                            }
                          },
                          e => _vm.$emit("change", e)
                        ],
                        input: e => _vm.$emit("input", e),
                        blur: e => _vm.inputLostFocus(e),
                        focus: e => _vm.inputFocused(e)
                      }
                    })
                  : _vm.typeUse === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.localValue,
                          expression: "localValue"
                        },
                        {
                          name: "two-dp",
                          rawName: "v-two-dp",
                          value: _vm.hasPrecision && _vm.isTypeNumber,
                          expression: "hasPrecision && isTypeNumber"
                        }
                      ],
                      ref: "inputElement",
                      class: [
                        _vm.inputClass,
                        {
                          "form-control":
                            !_vm.suffix && !_vm.useInlineMaxLength,
                          "text-lowercase": _vm.lowercase,
                          "text-uppercase": _vm.uppercase,
                          error: _vm.hasError,
                          primary: _vm.primary
                        }
                      ],
                      attrs: {
                        id: _vm.id,
                        name: _vm.name,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                        maxlength: _vm.maxlength,
                        max: _vm.max,
                        min: _vm.min,
                        autocomplete: _vm.disableAutoComplete
                          ? "disable-autocomplete"
                          : "on",
                        type: "radio"
                      },
                      domProps: { checked: _vm._q(_vm.localValue, null) },
                      on: {
                        keydown: e => _vm.$emit("keydown", e),
                        keyup: e => _vm.$emit("keyup", e),
                        change: [
                          function($event) {
                            _vm.localValue = null
                          },
                          e => _vm.$emit("change", e)
                        ],
                        input: e => _vm.$emit("input", e),
                        blur: e => _vm.inputLostFocus(e),
                        focus: e => _vm.inputFocused(e)
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.localValue,
                          expression: "localValue"
                        },
                        {
                          name: "two-dp",
                          rawName: "v-two-dp",
                          value: _vm.hasPrecision && _vm.isTypeNumber,
                          expression: "hasPrecision && isTypeNumber"
                        }
                      ],
                      ref: "inputElement",
                      class: [
                        _vm.inputClass,
                        {
                          "form-control":
                            !_vm.suffix && !_vm.useInlineMaxLength,
                          "text-lowercase": _vm.lowercase,
                          "text-uppercase": _vm.uppercase,
                          error: _vm.hasError,
                          primary: _vm.primary
                        }
                      ],
                      attrs: {
                        id: _vm.id,
                        name: _vm.name,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                        maxlength: _vm.maxlength,
                        max: _vm.max,
                        min: _vm.min,
                        autocomplete: _vm.disableAutoComplete
                          ? "disable-autocomplete"
                          : "on",
                        type: _vm.typeUse
                      },
                      domProps: { value: _vm.localValue },
                      on: {
                        keydown: e => _vm.$emit("keydown", e),
                        keyup: e => _vm.$emit("keyup", e),
                        change: e => _vm.$emit("change", e),
                        input: [
                          function($event) {
                            if ($event.target.composing) return
                            _vm.localValue = $event.target.value
                          },
                          e => _vm.$emit("input", e)
                        ],
                        blur: e => _vm.inputLostFocus(e),
                        focus: e => _vm.inputFocused(e)
                      }
                    }),
                _vm._v(" "),
                _vm.suffix
                  ? _c("span", { staticClass: "suffix" }, [
                      _vm._v(_vm._s(_vm.suffix))
                    ])
                  : _vm.maxlength &&
                    _vm.useInlineMaxLength &&
                    _vm.showInlineMaxLength
                  ? _c("span", { staticClass: "suffix small" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.value ? _vm.value.length : 0) +
                          "/" +
                          _vm._s(_vm.maxlength) +
                          "\n\t\t\t\t"
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.isTypePassword && _vm.showPassword
              ? _c("InlineSvg", {
                  staticClass: "show-password-icon",
                  attrs: {
                    src: "images/icons/ic-visible.svg",
                    width: _vm.iconWidth
                  },
                  on: { click: () => _vm.setShowPassword(false) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isTypePassword && !_vm.showPassword
              ? _c("InlineSvg", {
                  staticClass: "show-password-icon",
                  attrs: {
                    src: "images/icons/ic-hidden.svg",
                    width: _vm.iconWidth
                  },
                  on: { click: () => _vm.setShowPassword(true) }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasError || _vm.warning
        ? _c("div", { staticClass: "para-3 error-text" }, [
            _vm.hasError
              ? _c("span", { staticClass: "text-bold error-message" }, [
                  _c("i"),
                  _vm._v(_vm._s(_vm.error[0]))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.warning
              ? _c("span", { staticStyle: { color: "#00cfcc" } }, [
                  _vm.hasError ? _c("br") : _vm._e(),
                  _c("i"),
                  _vm._v(_vm._s(_vm.warning))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      this.$slots.helperText
        ? _c("div", { staticClass: "helper-text" }, [_vm._t("helperText")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }