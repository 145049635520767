<template>
	<div class="slider-container">
		<input type="range" :min="minValue" :max="maxValue" class="slider" v-model="localValue" />
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { debounce as _debounce } from 'lodash'

	export default {
		props: {
			minValue: {
				type: Number,
				default: 0,
			},
			maxValue: {
				type: Number,
				default: 10,
			},
			value: {
				type: Number,
				default: 2,
			},
		},
		mounted() {
			this.updateSliderBackground(this.value)
		},
		computed: {
			...mapGetters('Auth', ['user']),
			localValue: {
				get: function getter() {
					return this.value
				},
				set: function setter(value) {
					value = parseInt(value, 10)
					this.$emit('valueChange', value)
					this.updateSliderBackground(value)
					this.sendAnalytics(value)
				},
			},
		},
		methods: {
			updateSliderBackground(value) {
				const percentageProgress = (value / this.maxValue) * 100
				const slider = this.$el.querySelector('.slider')
				slider.style.background = `linear-gradient(to right, var(--stan-primary-primary-color) 0%, var(--stan-primary-primary-color) ${percentageProgress}%, #ccc ${percentageProgress}%, #ccc 100%)`
			},
			sendAnalytics: _debounce(function _(value) {
				this.$stanAnalytics('admin-appSlider-change', {
					meta: { user_id: this.user?.user_id, username: this.user?.username },
					props: {
						slider_name: this.name,
						slider_value: value,
						slider_min_value: this.minValue,
						slider_max_value: this.maxValue,
					},
				})
			}, 1000),
		},
	}
</script>

<style scoped>
	.slider-container {
		width: 100%;
	}

	.slider {
		-webkit-appearance: none;
		width: 100%;
		height: 8px;
		background: linear-gradient(
			to right,
			var(--stan-primary-primary-color) 0%,
			var(--stan-primary-primary-color) var(--slider-value, 50%),
			#ccc var(--slider-value, 50%),
			#ccc 100%
		);
		outline: none;
		border-radius: 5px;
	}

	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--stan-primary-primary-color);
		cursor: pointer;
		border: 2px solid #fff;
		box-shadow: var(--stan-box-shadow);
		transition: transform 0.2s ease-in-out;
	}

	.slider::-moz-range-thumb {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--stan-primary-primary-color);
		cursor: pointer;
		border: 2px solid #fff;
		box-shadow: var(--stan-box-shadow);
		transition: transform 0.2s ease-in-out;
	}

	.slider:active::-webkit-slider-thumb {
		transform: scale(1.2);
	}

	.slider:active::-moz-range-thumb {
		transform: scale(1.2);
	}
</style>
