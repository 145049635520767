<template>
	<div ref="animation" class="animation"></div>
</template>
<script>
	import Lottie from 'lottie-web'

	export default {
		name: 'AppModal',
		props: {
			cacheAnimation: { type: Boolean, default: false },
			loop: { type: [Number, Boolean], default: false },
			autoPlay: { type: Boolean, default: false },
			delay: { type: Number, default: 0 },
			speed: { type: Number, default: 1 },
			name: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				lottieAnimation: undefined,
			}
		},
		computed: {},
		watch: {},
		async mounted() {
			// Loads animation into cache for future usage
			if (this.cacheAnimation) {
				await this.loadAnimation()
			} else {
				await this.init()
			}
			if (this.delay > 0 && this.autoPlay) {
				setTimeout(this.play, this.delay)
			} else if (this.autoPlay) {
				this.play()
			}
			this.$emit('ready')
		},
		onUnmounted() {
			this.destroy()
		},
		methods: {
			async loadAnimation() {
				return import(`~/assets/lottie/${this.name}`)
			},
			async init() {
				const data = await this.loadAnimation()
				this.lottieAnimation = Lottie.loadAnimation({
					container: this.$refs.animation,
					renderer: 'svg',
					loop: this.loop,
					autoplay: false,
					animationData: data,
				})

				this.lottieAnimation.setSpeed(this.speed)

				this.lottieAnimation.addEventListener('loopComplete', () => {
					this.$emit('loopComplete')
				})
				this.lottieAnimation.addEventListener('complete', () => {
					this.$emit('complete')
				})
				this.lottieAnimation.addEventListener('enterFrame', () => {
					this.$emit('enterFrame')
				})
				this.lottieAnimation.addEventListener('segmentStart', () => {
					this.$emit('segmentStart')
				})
			},
			destroy() {
				if (this.lottieAnimation) {
					this.lottieAnimation.destroy()
				}
			},
			play() {
				if (this.lottieAnimation) {
					this.$emit('play')
					this.lottieAnimation.play()
				}
			},
			stop() {
				if (this.lottieAnimation) {
					this.lottieAnimation.stop()
				}
			},
			getDuration(inFrames = false) {
				if (this.lottieAnimation) {
					this.lottieAnimation.getDuration(inFrames)
				}
				return 0
			},
		},
	}
</script>
<style lang="scss" scoped></style>
