const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/analytics',
	name: 'AnalyticsIndex',
	redirect: '/analytics',
	component: MainContainer,
	props: true,
	children: [
		{
			path: '',
			name: 'analytics',
			component: () => import('./Analytics').then(m => m.default || m),
			meta: {
				pageTitle: 'Analytics',
				breadcrumbs: [{ title: 'My Analytics', active: true }],
				showAppBanner: true,
			},
		},
		{
			path: ':page',
			name: 'paginate_analytics',
			component: () => import('./Analytics').then(m => m.default || m),
			meta: {
				pageTitle: 'Analytics',
				breadcrumbs: [{ title: 'My Analytics', active: true }],
			},
		},
		{
			path: 'course/:page_id',
			name: 'course_analytics',
			component: () => import('./CourseAnalytics').then(m => m.default || m),
			meta: {
				pageTitle: 'Store',
				breadcrumbs: [{ title: 'Home', to: 'dashboard' }],
			},
		},
		{
			path: 'affiliate/:page_id',
			name: 'affiliate_analytics',
			component: () => import('./AffiliateAnalytics').then(m => m.default || m),
			meta: {
				pageTitle: 'Store',
				breadcrumbs: [{ title: 'Home', to: 'dashboard' }],
			},
		},
	],
}

export default moduleRoutes
