var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stan-device preview-type-store" }, [
    _vm.loading || !_vm.getStore
      ? _c(
          "div",
          {
            staticClass: "mx-auto store-theme",
            class: {
              "funnel-site-preview-section": _vm.funnelClass,
              "site-preview-section": !_vm.funnelClass
            }
          },
          [
            _c("CalendarSkeleton", {
              staticClass: "mb-5",
              attrs: { heading: false }
            }),
            _vm._v(" "),
            _c("Skeleton", { staticClass: "mb-5" }),
            _vm._v(" "),
            _c("Skeleton", { staticClass: "mb-5" }),
            _vm._v(" "),
            _c("Skeleton", { staticClass: "mb-5" }),
            _vm._v(" "),
            _c("Skeleton", { staticClass: "mb-5" })
          ],
          1
        )
      : _c(
          "div",
          {
            ref: "device-wrapper",
            staticClass: "mx-auto store-theme",
            class: {
              "funnel-site-preview-section": _vm.funnelClass,
              "site-preview-section": !_vm.funnelClass
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "site__prev__masking store-theme",
                class: _vm.storeTheme
              },
              [
                _vm.funnelSkeleton === true
                  ? _c("PreviewSkeleton")
                  : _c(
                      "div",
                      { staticClass: "store-layout" },
                      [
                        _c("div", {
                          staticClass: "d-none",
                          attrs: { id: "fixed-background" }
                        }),
                        _vm._v(" "),
                        _c("Header", {
                          staticClass: "mb-3",
                          attrs: {
                            full_name: _vm.user.full_name,
                            username: _vm.user.username,
                            userBio: _vm.user.bio,
                            user_image: _vm.user.profile_image,
                            storeTheme: _vm.storeTheme,
                            socialIcons: _vm.user.data.socials
                          }
                        }),
                        _vm._v(" "),
                        _vm.sections
                          ? _c(
                              "div",
                              { staticClass: "store-content" },
                              _vm._l(_vm.sections, function(section, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "section" },
                                  [
                                    section.data["title"]
                                      ? _c(
                                          "div",
                                          { staticClass: "section-title" },
                                          [
                                            _vm._v(
                                              _vm._s(section.data["title"])
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "w-100" },
                                      [
                                        _c(
                                          "TransitionGroup",
                                          { attrs: { name: "fade" } },
                                          _vm._l(section.pages, function(
                                            block
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: block.page_id,
                                                staticClass:
                                                  "mb-3 default-cursor preview-button"
                                              },
                                              [
                                                block.data &&
                                                block.data.button &&
                                                block.status === 2
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm.isEmbed(
                                                          block.data.button
                                                            .layout
                                                        )
                                                          ? _c("MediaPreview", {
                                                              attrs: {
                                                                embedUrl:
                                                                  block.data
                                                                    .product
                                                                    .link
                                                                    .embed_url,
                                                                embedStyle:
                                                                  block.data
                                                                    .product
                                                                    .link
                                                                    .embed_style,
                                                                embedType:
                                                                  block.data
                                                                    .product
                                                                    .link
                                                                    .embed_type
                                                              }
                                                            })
                                                          : block.data.product
                                                              .type ==
                                                            "lead-magnet"
                                                          ? _c(
                                                              "LeadMagnetButton",
                                                              {
                                                                ref: "form",
                                                                refInFor: true,
                                                                staticStyle: {
                                                                  "pointer-events":
                                                                    "none"
                                                                },
                                                                attrs: {
                                                                  user:
                                                                    _vm.user,
                                                                  title:
                                                                    block.data
                                                                      .button
                                                                      .title,
                                                                  heading:
                                                                    block.data
                                                                      .button
                                                                      .heading,
                                                                  image:
                                                                    block.data
                                                                      .button
                                                                      .image,
                                                                  tagline:
                                                                    block.data
                                                                      .button
                                                                      .tagline,
                                                                  questions: _vm.questionsToForm(
                                                                    block
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              }
                                                            )
                                                          : _vm.isLayoutPreview(
                                                              block.data.button
                                                                .layout
                                                            )
                                                          ? _c(
                                                              "SitePreviewButton",
                                                              {
                                                                ref: "form",
                                                                refInFor: true,
                                                                attrs: {
                                                                  user:
                                                                    _vm.user,
                                                                  title:
                                                                    block.data
                                                                      .button
                                                                      .title,
                                                                  heading:
                                                                    block.data
                                                                      .button
                                                                      .heading,
                                                                  image:
                                                                    block.data
                                                                      .button
                                                                      .image,
                                                                  tagline:
                                                                    block.data
                                                                      .button
                                                                      .tagline,
                                                                  reviews:
                                                                    block.data
                                                                      .product
                                                                      .reviews,
                                                                  "product-type":
                                                                    block.data
                                                                      .product
                                                                      .type,
                                                                  price: _vm.getPrice(
                                                                    block.data
                                                                      .product
                                                                  ),
                                                                  quantity: _vm.productQuantity(
                                                                    block.data
                                                                      .product
                                                                  ),
                                                                  "page-id":
                                                                    block.page_id,
                                                                  product:
                                                                    block.data
                                                                      .product,
                                                                  isDemo: true
                                                                }
                                                              }
                                                            )
                                                          : _vm.isLayoutCallout(
                                                              block.data.button
                                                                .layout
                                                            )
                                                          ? _c(
                                                              "SiteCalloutButton",
                                                              {
                                                                ref: "form",
                                                                refInFor: true,
                                                                attrs: {
                                                                  user:
                                                                    _vm.user,
                                                                  title:
                                                                    block.data
                                                                      .button
                                                                      .title,
                                                                  heading:
                                                                    block.data
                                                                      .button
                                                                      .heading,
                                                                  image:
                                                                    block.data
                                                                      .button
                                                                      .image,
                                                                  tagline:
                                                                    block.data
                                                                      .button
                                                                      .tagline,
                                                                  reviews:
                                                                    block.data
                                                                      .product
                                                                      .reviews,
                                                                  "product-type":
                                                                    block.data
                                                                      .product
                                                                      .type,
                                                                  price: _vm.getPrice(
                                                                    block.data
                                                                      .product
                                                                  ),
                                                                  quantity: _vm.productQuantity(
                                                                    block.data
                                                                      .product
                                                                  ),
                                                                  "page-id":
                                                                    block.page_id,
                                                                  product:
                                                                    block.data
                                                                      .product,
                                                                  isDemo: true
                                                                }
                                                              }
                                                            )
                                                          : _vm.isLayoutButton(
                                                              block.data.button
                                                                .layout
                                                            )
                                                          ? _c("SiteButton", {
                                                              attrs: {
                                                                user: _vm.user,
                                                                title:
                                                                  block.data
                                                                    .button
                                                                    .title,
                                                                image:
                                                                  block.data
                                                                    .button
                                                                    .image,
                                                                reviews:
                                                                  block.data
                                                                    .product
                                                                    .reviews,
                                                                "product-type":
                                                                  block.data
                                                                    .product
                                                                    .type,
                                                                price: _vm.getPrice(
                                                                  block.data
                                                                    .product
                                                                ),
                                                                quantity: _vm.productQuantity(
                                                                  block.data
                                                                    .product
                                                                )
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm.blocks
                          ? _c(
                              "div",
                              { staticClass: "store-content" },
                              _vm._l(_vm.blocks, function(block) {
                                return _c(
                                  "div",
                                  {
                                    key: block.page_id,
                                    staticClass:
                                      "mb-3 default-cursor preview-button"
                                  },
                                  [
                                    block.data &&
                                    block.data.button &&
                                    block.status === 2
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isEmbed(
                                              block.data.button.layout
                                            )
                                              ? _c("MediaPreview", {
                                                  attrs: {
                                                    embedUrl:
                                                      block.data.product.link
                                                        .embed_url,
                                                    embedStyle:
                                                      block.data.product.link
                                                        .embed_style,
                                                    embedType:
                                                      block.data.product.link
                                                        .embed_type
                                                  }
                                                })
                                              : block.data.product.type ==
                                                "lead-magnet"
                                              ? _c("LeadMagnetButton", {
                                                  ref: "form",
                                                  refInFor: true,
                                                  staticStyle: {
                                                    "pointer-events": "none"
                                                  },
                                                  attrs: {
                                                    user: _vm.user,
                                                    title:
                                                      block.data.button.title,
                                                    heading:
                                                      block.data.button.heading,
                                                    image:
                                                      block.data.button.image,
                                                    tagline:
                                                      block.data.button.tagline,
                                                    questions: _vm.questionsToForm(
                                                      block
                                                    ),
                                                    disabled: true
                                                  }
                                                })
                                              : _vm.isLayoutPreview(
                                                  block.data.button.layout
                                                )
                                              ? _c("SitePreviewButton", {
                                                  ref: "form",
                                                  refInFor: true,
                                                  attrs: {
                                                    user: _vm.user,
                                                    title:
                                                      block.data.button.title,
                                                    heading:
                                                      block.data.button.heading,
                                                    image:
                                                      block.data.button.image,
                                                    tagline:
                                                      block.data.button.tagline,
                                                    reviews:
                                                      block.data.product
                                                        .reviews,
                                                    "product-type":
                                                      block.data.product.type,
                                                    price: _vm.getPrice(
                                                      block.data.product
                                                    ),
                                                    quantity: _vm.productQuantity(
                                                      block.data.product
                                                    ),
                                                    "page-id": block.page_id,
                                                    product: block.data.product,
                                                    isDemo: true
                                                  }
                                                })
                                              : _vm.isLayoutCallout(
                                                  block.data.button.layout
                                                )
                                              ? _c("SiteCalloutButton", {
                                                  ref: "form",
                                                  refInFor: true,
                                                  attrs: {
                                                    user: _vm.user,
                                                    title:
                                                      block.data.button.title,
                                                    heading:
                                                      block.data.button.heading,
                                                    image:
                                                      block.data.button.image,
                                                    tagline:
                                                      block.data.button.tagline,
                                                    reviews:
                                                      block.data.product
                                                        .reviews,
                                                    "product-type":
                                                      block.data.product.type,
                                                    price: _vm.getPrice(
                                                      block.data.product
                                                    ),
                                                    quantity: _vm.productQuantity(
                                                      block.data.product
                                                    ),
                                                    "page-id": block.page_id,
                                                    product: block.data.product,
                                                    isDemo: true
                                                  }
                                                })
                                              : _vm.isLayoutButton(
                                                  block.data.button.layout
                                                )
                                              ? _c("SiteButton", {
                                                  attrs: {
                                                    user: _vm.user,
                                                    title:
                                                      block.data.button.title,
                                                    image:
                                                      block.data.button.image,
                                                    reviews:
                                                      block.data.product
                                                        .reviews,
                                                    "product-type":
                                                      block.data.product.type,
                                                    price: _vm.getPrice(
                                                      block.data.product
                                                    ),
                                                    quantity: _vm.productQuantity(
                                                      block.data.product
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ],
                      1
                    )
              ],
              1
            )
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }