<template>
	<Transition :name="animation">
		<div class="full-screen" v-if="showing">
			<div class="mt-0">
				<div class="d-flex align-items-center flex-column justify-content-center full-view-height" id="site_preview">
					<AppLottie name="store_loading" class="loading-animation" autoPlay loop />
					<p class="subheader-text mt-3">Entering your store...</p>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
	const FADE_ANIMATION = 'fade'
	export default {
		data() {
			return {
				showing: false,
				animation: FADE_ANIMATION,
			}
		},
		props: {
			skipFadeIn: {
				type: Boolean,
				default: false,
			},
		},
		mounted() {},
		methods: {
			show() {
				if (this.skipFadeIn) {
					this.animation = ''
				}
				this.showing = true
			},
			hide() {
				this.animation = FADE_ANIMATION
				this.$nextTick(() => {
					this.showing = false
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.full-screen {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		background: #fff;
	}
	.full-view-height {
		height: 100vh;
	}
	.loading-animation {
		width: min(100px, 80vw);
	}

	.subheader-text {
		font-size: 16px;
		font-weight: 400;
		font-family: 'Plus Jakarta Sans';
		color: var(--stan-text-light-color);
		text-align: center;
	}
</style>
