<template>
	<div class="reviews-wrapper">
		<div class="reviews" ref="reviewsContainer">
			<div v-for="(review, index) in reviews" :key="index" class="item">
				<star-rating
					v-model="review.rating"
					:star-size="12"
					:increment="0.5"
					:max-rating="5"
					:show-rating="false"
					:read-only="true"
					active-color="#FEC337"
					:animate="true"
				></star-rating>
				<div class="image-star-rating d-none" :style="{ '--avg-rating': review.rating }">
					<svg
						v-for="index in Math.ceil(review.rating)"
						width="13"
						height="13"
						viewBox="0 0 13 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z"
							fill="#FEC337"
						/>
					</svg>
					<span>{{ review.rating }}</span>
				</div>
				<div class="heading">
					<image-preview v-if="review.image" :source="review.image" :width="35" :height="35" class="image" alt="review image" />
					<div v-else class="image">
						<img src="https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg" alt="reviewer frame" />
					</div>
					<div class="title-rating-wrapper">
						<p>{{ review.name }}</p>
					</div>
				</div>

				<div class="review-description small-review" ref="reviewDescriptions">
					{{ review.description }}
				</div>

				<button v-if="overflowMap[index]" class="show-more-btn" @click="openModal(index)" :class="{ disabled: isPreview }">
					<span>Show More</span>
				</button>
			</div>
		</div>
		<div class="button-area-reviews" v-if="reviews.length > 0 && !(disableLeft && disableRight)">
			<button class="nav-btn left-arrow" :disabled="disableLeft" @click="scrollLeft" style="margin-right: 8px">
				<svg
					width="32"
					height="32"
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					:style="{ opacity: disableLeft ? 0.4 : 1 }"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M15.1968 23.3633C14.9039 23.6562 14.429 23.6562 14.1361 23.3633L7.46954 16.6968C7.32888 16.5562 7.24986 16.3654 7.24986 16.1665C7.24986 15.9676 7.32888 15.7768 7.46953 15.6362L14.1361 8.96958C14.429 8.67669 14.9039 8.67669 15.1968 8.96958C15.4897 9.26247 15.4897 9.73735 15.1968 10.0302L9.81052 15.4165L23.9998 15.4165C24.414 15.4165 24.7498 15.7523 24.7498 16.1665C24.7498 16.5807 24.414 16.9165 23.9998 16.9165H9.81053L15.1968 22.3027C15.4897 22.5956 15.4897 23.0704 15.1968 23.3633Z"
						fill="var(--stan-store-theme-primary-text-color)"
					/>
				</svg>
			</button>
			<button class="nav-btn right-arrow" :disabled="disableRight" @click="scrollRight">
				<svg
					width="32"
					height="32"
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					:style="{ opacity: disableRight ? 0.4 : 1 }"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M16.803 8.96967C17.0959 8.67678 17.5707 8.67678 17.8636 8.96967L24.5302 15.6362C24.6709 15.7768 24.7499 15.9676 24.7499 16.1665C24.7499 16.3654 24.6709 16.5562 24.5302 16.6969L17.8636 23.3634C17.5708 23.6563 17.0959 23.6563 16.803 23.3634C16.5101 23.0705 16.5101 22.5957 16.803 22.3028L22.1892 16.9165H8C7.58579 16.9165 7.25 16.5807 7.25 16.1665C7.25 15.7523 7.58579 15.4165 8 15.4165H22.1892L16.803 10.0303C16.5101 9.73744 16.5101 9.26257 16.803 8.96967Z"
						fill="var(--stan-store-theme-primary-text-color)"
					/>
				</svg>
			</button>
		</div>

		<transition name="fade-modal">
			<div v-if="showModal" class="review-modal-backdrop reviews" @click.self="closeModal">
				<div class="review-modal item" :key="selectedReviewIndex">
					<div class="modal-review-content">
						<star-rating
							v-model="selectedReview.rating"
							:star-size="12"
							:increment="0.5"
							:max-rating="5"
							:show-rating="false"
							:read-only="true"
							active-color="#FEC337"
							:animate="true"
						></star-rating>
						<div class="image-star-rating d-none" :style="{ '--avg-rating': selectedReview.rating }">
							<svg
								v-for="index in Math.ceil(selectedReview.rating)"
								width="13"
								height="13"
								viewBox="0 0 13 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z"
									fill="#FEC337"
								/>
							</svg>
							<span>{{ selectedReview.rating }}</span>
						</div>

						<div class="heading">
							<image-preview
								v-if="selectedReview.image"
								:source="selectedReview.image"
								:width="35"
								:height="35"
								class="image"
								alt="review image"
							/>
							<div v-else class="image">
								<img src="https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg" alt="reviewer frame" />
							</div>
							<div class="title-rating-wrapper">
								<p>{{ selectedReview.name }}</p>
							</div>
							<button class="close-btn" @click="closeModal">
								<CloseSvg fill="var(--review-text-color, var(--stan-store-theme-primary-text-color))" />
							</button>
						</div>

						<div class="review-description">
							{{ selectedReview.description }}
						</div>
						<div class="nav-area">
							<div class="progress">{{ selectedReviewIndex + 1 }} / {{ reviews.length }}</div>
							<div class="button-area">
								<button class="nav-btn left-arrow" :disabled="!(selectedReviewIndex > 0)" @click.stop="goLeft">
									<LeftArrow
										fill="var(--review-text-color, var(--stan-store-theme-primary-text-color))"
										:style="{ opacity: !(selectedReviewIndex > 0) ? 0.5 : 1 }"
									/>
								</button>
								<button class="nav-btn right-arrow" :disabled="!(selectedReviewIndex < reviews.length - 1)" @click.stop="goRight">
									<RightArrow
										fill="var(--review-text-color, var(--stan-store-theme-primary-text-color))"
										:style="{ opacity: !(selectedReviewIndex < reviews.length - 1) ? 0.5 : 1 }"
									/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import ImagePreview from './ImagePreview'
	import LeftArrow from '~/assets/images/ic-left-arrow.svg'
	import RightArrow from '~/assets/images/ic-right-arrow.svg'
	import CloseSvg from '~/assets/images/ic-dark-close.svg'

	export default {
		name: 'UserReviews',
		components: {
			StarRating: () => import('vue-star-rating'),
			ImagePreview,
			LeftArrow,
			RightArrow,
			CloseSvg,
		},
		props: {
			reviews: {
				type: Array,
				default: () => [],
			},
			isPreview: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				overflowMap: [],
				showModal: false,
				selectedReviewIndex: 0,
				tick: 0,
				direction: null,
				disableLeft: true,
				disableRight: false,
			}
		},
		computed: {
			selectedReview() {
				return this.reviews[this.selectedReviewIndex] || {}
			},
		},
		mounted() {
			this.checkOverflow()
		},
		methods: {
			checkOverflow() {
				if (!this.$refs.reviewDescriptions) return

				this.overflowMap = this.$refs.reviewDescriptions.map(descEl => {
					const contentHeight = descEl.scrollHeight
					return contentHeight > 180
				})

				const container = this.$refs.reviewsContainer
				if (container) {
					container.addEventListener('scroll', this.checkScrollArrows)
				}

				this.$nextTick(() => {
					this.checkScrollArrows()
				})
			},

			openModal(index) {
				this.$stanAnalytics('testimonial-show-more-click', {
					meta: { username: this.$route.params.username },
					props: {
						review: this.reviews[index],
					},
				})
				this.selectedReviewIndex = index
				this.showModal = true
				document.body.style.overflow = 'hidden'
				this.$emit('modalOpen', true)
			},

			closeModal() {
				this.showModal = false
				this.direction = null
				document.body.style.overflow = 'auto'
				this.$emit('modalOpen', false)
			},

			goLeft() {
				if (this.selectedReviewIndex > 0) {
					this.direction = 'slide-left'
					this.selectedReviewIndex--
				}
			},

			goRight() {
				if (this.selectedReviewIndex < this.reviews.length - 1) {
					this.direction = 'slide-right'
					this.selectedReviewIndex++
				}
			},
			scrollLeft() {
				const isNuxt = !!this.$mobile
				isNuxt &&
					this.$stanAnalytics('testimonial-click-left', {
						meta: { username: this.$route.params.username },
					})
				const container = this.$refs.reviewsContainer

				// nuxt vs admin checks
				const isMobile = isNuxt ? this.$mobile.isMobile() : this.$commonHelper.isMobile()

				const scrollAmount = isMobile ? 240 : 480 // one card in mobile, 2 in desktop
				container.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
			},

			scrollRight() {
				const isNuxt = !!this.$mobile
				isNuxt &&
					this.$stanAnalytics('testimonial-click-left', {
						meta: { username: this.$route.params.username },
					})
				const container = this.$refs.reviewsContainer

				// nuxt vs admin checks
				const isMobile = isNuxt ? this.$mobile.isMobile() : this.$commonHelper.isMobile()

				const scrollAmount = isMobile ? 240 : 480 // one card in mobile, 2 in desktop
				container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
			},

			checkScrollArrows() {
				const container = this.$refs.reviewsContainer
				if (!container) return

				this.disableLeft = container.scrollLeft <= 0

				const maxScrollLeft = container.scrollWidth - container.clientWidth
				this.disableRight = container.scrollLeft >= maxScrollLeft
			},
		},
		beforeDestroy() {
			const container = this.$refs.reviewsContainer
			if (container) {
				container.removeEventListener('scroll', this.checkScrollArrows)
			}
		},
	}
</script>

<style scoped lang="scss">
	.fade-modal-enter-active,
	.fade-modal-leave-active {
		transition: opacity 0.3s ease;
	}
	.fade-modal-enter,
	.fade-modal-leave-to {
		opacity: 0;
	}

	@media (max-width: 767px) {
		.slide-left-enter-active,
		.slide-left-leave-active,
		.slide-right-enter-active,
		.slide-right-leave-active {
			transition: none !important;
		}

		.slide-left-enter,
		.slide-left-leave-to,
		.slide-right-enter,
		.slide-right-leave-to {
			transform: none !important;
			opacity: 1 !important;
		}
	}
	.reviews-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.reviews {
		list-style: none;
		white-space: nowrap;
		width: 100%;
		overflow-x: scroll;
		display: flex;
		flex-wrap: nowrap;
		-webkit-overflow-scrolling: touch;
		padding: 24px;
		align-items: flex-start;

		&::-webkit-scrollbar {
			display: none;
		}
		.vue-star-rating {
			margin-bottom: 6px;
		}
		.image-star-rating {
			svg {
				margin-right: 2px;
			}
		}
		.item {
			background-color: #f3f6fd;
			width: 240px;
			height: 300px;
			flex: 0 0 auto;
			white-space: normal;
			padding: 10px 15px;
			margin-right: 15px;
			box-sizing: border-box;
			position: relative;

			.heading {
				display: flex;
				align-items: center;
				margin-bottom: 8px;
				margin-top: 8px !important;

				.image {
					width: 35px;
					height: 35px;
					background-color: #ced6e7;
					border-radius: 50%;
					margin-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 17px;
						display: block;
					}
				}
				.title-rating-wrapper {
					p {
						font-size: 13px;
						line-height: 16px;
						margin-bottom: 0px;
					}
				}
			}

			.small-review {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 9;
				overflow: hidden;
				text-overflow: ellipsis;
				max-height: 180px;
				line-height: 19px !important;
				position: relative;
			}

			.show-more-btn {
				font-weight: 700;
				margin-top: auto;
				position: absolute;
				bottom: 8px;
				right: 16px;
				color: var(--review-text-color, var(--stan-store-theme-primary-text-color));

				&.disabled {
					pointer-events: none;
				}
			}
		}
	}

	.review-modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 0 !important;
		@media (max-width: 767px) {
			padding: 0 !important;
		}
	}

	.review-modal {
		width: 530px !important;
		height: 530px !important;
		padding: 16px;
		padding-top: 8px;
		position: relative;
		box-sizing: border-box;
		margin: 0 !important;
		@media (max-width: 767px) {
			width: 100% !important;
			height: 100% !important;
			border-radius: 0 !important;
		}

		.close-btn {
			margin-left: auto;
			font-size: 16px;
			cursor: pointer;
			align-self: start;
			position: absolute;
			top: 16px;
			right: 16px;
			svg path ::v-deep {
				fill: var(--review-text-color, var(--stan-store-theme-primary-text-color));
			}
		}
		.nav-area {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: flex-end;
			margin-top: auto;
			height: 32px;

			position: absolute;
			bottom: 8px;
			right: 8px;
			svg path ::v-deep {
				fill: var(--review-text-color, var(--stan-store-theme-primary-text-color));
			}
			.nav-btn {
				padding-left: 8px;
				padding-right: 8px;
			}
			.progress {
				font-size: 1rem;
				opacity: 0.7;
				color: var(--review-text-color, var(--stan-store-theme-primary-text-color));
			}
		}

		.modal-review-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			.heading {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 50px;
				font-size: 16px !important;

				.image {
					width: 35px;
					height: 35px;
					border-radius: 50%;
					margin-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 17px;
						display: block;
					}
				}
				.title-rating-wrapper {
					p {
						font-size: 13px;
						line-height: 16px;
						margin-bottom: 0;
					}
				}
			}
			.review-description {
				font-size: 15px;
				line-height: 19px;
				flex: 1;
				overflow-y: auto;
				max-height: unset !important;
				margin-bottom: 40px;
				&::-webkit-scrollbar {
					display: none;
				}
				@media (max-width: 767px) {
					border-radius: 0;
				}
			}
		}
	}

	.button-area-reviews {
		display: flex;
		margin-left: auto;
		justify-content: end;
		margin-right: 0.5rem;
	}
</style>
