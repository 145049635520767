/* eslint-disable quotes */
<template>
	<div class="navbar-wrapper">
		<div class="d-flex justify-content-end mb-2" v-if="isAdminLoggedInAsUser" style="color: red">
			Temporarily logged in as user [{{ user.user_id }}] [{{ user.username }}]
		</div>
		<nav class="navbar d-flex" v-if="user">
			<div class="d-inline-flex" v-if="showBreadcrumbs">
				<Breadcrumb :route="$route" @activeTab="activeTab" />
			</div>
			<div class="logo d-flex col-auto p-0" v-else>
				<RouterLink :to="{ name: 'dashboard' }">
					<img src="/images/stan-logo-text.svg" class="mr-2" />
				</RouterLink>
			</div>
			<div
				v-if="user && user.username"
				class="ml-sm-auto sharelinkmobile tooltip-share-storelink px-0 col col-sm-auto d-flex justify-content-end align-items-center"
			>
				<p
					class="text-truncate mb-0 mr-sm-3 align-items-center d-none d-sm-flex"
					style="text-align: right;"
					:key="displayUrl"
					v-if="!isAffiliateOnly"
				>
					<a :href="displayUrl" target="_blank" class="text-truncate h4-md h5 text-bold text-primary-color">{{
						removeHttps(displayUrl)
					}}</a>
				</p>
				<a :href="displayUrl" target="_blank" class="d-flex d-sm-none">
					<InlineSvg class="copy-icon stan-icon stan-icon-primary mr-1 cur" src="/images/icons/ic-link-purple.svg" />
				</a>

				<button
					class="share-button-navbar stan-icon-wrapper"
					v-clipboard="copyUrl"
					v-clipboard:success="clipboardSuccessHandler"
					v-if="!isAffiliateOnly"
				>
					<InlineSvg class="copy-icon stan-icon stan-icon-primary" src="/images/icons/ic-copy.svg" />
					<span class="d-none d-sm-inline-block h5 text-bold vertical-align-middle">Copy</span>
				</button>
			</div>
		</nav>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'
	import Vue from 'vue'
	import { directive as onClickOutside } from 'vue-on-click-outside'

	import Breadcrumb from '~/components/Breadcrumb'

	Vue.directive('on-click-outside', onClickOutside)

	export default {
		middleware: 'auth',
		components: {
			Breadcrumb,
		},
		data() {
			return { windowWidth: window.innerWidth }
		},
		created() {
			if (this.user && this.user.status === '0') this.logout()
		},
		computed: {
			...mapGetters('Auth', ['user', 'isAdminLoggedInAsUser']),
			...mapGetters('Global', ['getBreadcrumbs']),
			breadcrumbs() {
				if (this.getBreadcrumbs && this.getBreadcrumbs.length > 0) {
					return this.getBreadcrumbs
				}
				return this.$route.meta.breadcrumbs
			},
			displayUrl() {
				if (this.$route.name === 'linksite_edit_page' && this.$route.params.pageUrl) {
					return this.$route.params.pageUrl
				}
				return this.storeUrl
			},
			routeTitle() {
				return this.$route?.meta?.pageTitle
			},
			storeUrl() {
				return `${this.siteSettings.frontDomain}${this.user.username}`
			},
			isAffiliateOnly() {
				return this.user.subscription_tier === this.$constants.TIER.AFFILIATE
			},
			isMobile() {
				return this.windowWidth <= 991
			},
			showBreadcrumbs() {
				if (!this.breadcrumbs) {
					return false
				}
				if (!this.isMobile) {
					return true
				}
				return this.breadcrumbs.length > 1
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			removeHttps(url) {
				return url.replace(/^https?:\/\//, '')
			},
			async logout() {
				clearInterval(this.t)
				await this.$store.dispatch('Auth/logout')
				localStorage.clear()
				this.$router.push({ name: 'login_page' }).catch(() => {})
			},
			copyUrl() {
				// Access dynamically avoid caching initial value of displayUrl in v-clipboard
				return this.displayUrl
			},
			clipboardSuccessHandler() {
				this.$stanNotify({
					type: 'success', // can be 'info', 'warning', 'success', 'error'
					title: this.$t('URL Copied!'),
					duration: 3000,
				})
				// eslint-disable-next-line no-underscore-dangle
				if (window._ctrack) {
					// eslint-disable-next-line no-undef
					_ctrack.track('link_shared')
				}
				this.$stanAnalytics('link-shared', {
					meta: { user_id: this.user.user_id, username: this.user.username },
				})
			},
			activeTab(tabName, query, params) {
				this.$router.push({ name: tabName, query, params }).catch(() => {})
			},
			updateWindowWidth() {
				this.windowWidth = window.innerWidth
			},
		},
		mounted() {
			window.addEventListener('resize', this.updateWindowWidth)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.updateWindowWidth)
		},
	}
</script>
<style lang="scss" scoped>
	.navbar-wrapper {
		z-index: 1;
		border-bottom: 1px solid var(--stan-primary-light-color);
		.navbar {
			position: -webkit-sticky;
			position: sticky;
			height: 75px;
			top: 0px;
			padding-left: 20px;
			min-height: 50px;
			align-items: center;
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			@media (min-width: 576px) {
				justify-content: flex-end;
			}
			.page-title {
				font-size: 24px;
				font-weight: 300;
				color: var(--stan-white);
			}
		}
		.share-button-navbar {
			white-space: nowrap;
			background: transparent;
			border: none;
			justify-self: flex-end;
			min-width: 24px;
			flex: 0 0 auto;
			width: auto;
			background-color: var(--stan-white);
			@media (min-width: 576px) {
				height: 36px;
				background: var(--stan-white);
				border: 1px solid var(--stan-primary-primary-color);
				color: var(--stan-primary-primary-color);
				justify-self: unset;
				min-width: none;
				padding: 0 15px;
				&:hover {
					background-color: var(--stan-primary-light-color);
				}
			}
			border-radius: 10px;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.04em;
			font-weight: 600;

			.vertical-align-middle {
				vertical-align: middle;
			}

			::v-deep {
				svg path {
					fill: var(--stan-primary-primary-color);
				}
			}
		}

		.page-header {
			font-weight: 600;
			font-size: 18px;
			line-height: 25px;
			color: var(--stan-primary-dark-color);
			margin: 0;
		}

		.sharelinkmobile {
			max-width: calc(100% - 95px);
			@media (min-width: 576px) {
				max-width: 70%;
			}
		}
	}
</style>
