<template>
	<div class="main-wrapper">
		<!-- Course / home Section -->
		<div class="main-section" v-if="screenSize > 768 || (screenSize <= 768 && !showCoursesList)">
			<!-- Banner -->
			<div class="exit-preview-banner" @click="close">
				Click to exit preview
			</div>
			<!-- Home -->
			<div v-if="showHome" class="lesson-details">
				<div v-if="bannerImage" class="image-wrapper">
					<AppImage :src="bannerImage" class="banner-image" />
				</div>
				<div style="display: flex; flex-direction: column; gap: 40px; margin-top: 24px;">
					<h1 class="title-text" v-if="course.data.title">{{ course.data.title }}</h1>
					<div class="description" v-if="course.data.description">
						<TipTapViewer v-if="course.data.description" v-model="course.data.description" read-only class="px-5-mod mb-5" />
					</div>
				</div>
				<div class="button-wrapper">
					<div class="controller-button next-button" v-show="canShowNext" @click="firstLesson">
						<div class="controller-button-text align-items-end flex-shrink-full">
							<div class="para-3 button-header-text">First Lesson:</div>
							<div class="para-3 font-semibold button-subheader-text text-ellipsis text-right">
								{{ nextLessonTitle }}
							</div>
						</div>
						<svg width="8" height="16" fill="var(--stan-store-custom-highlight-color)" xmlns="http://www.w3.org/2000/svg" class="">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z"
								fill="inherit"
							></path>
						</svg>
					</div>
				</div>
			</div>
			<!-- Course -->
			<div v-else-if="currentLesson" class="lesson-details">
				<Video
					v-if="lessonHasVideo"
					:video-src="currentLesson.data.video"
					:video-stream-src="currentLesson.data.video_stream"
					:fcp-complete="true"
					:showMask="false"
					class="pages-block py-6 px-0"
					key="video"
					videoId="preview-course-desktop-video"
				/>
				<div class="video-placeholder mb-6" v-else-if="!hideVideo">
					<div class="video-button-wrapper">
						<div class="video-icon"><img src="https://assets.stanwith.me/graphics/video-play.svg" /></div>
						<span>No Video Yet</span>
					</div>
				</div>

				<div style="display: flex; flex-direction: column; gap: 40px;">
					<h1 class="title-text">{{ currentLesson.data.title }}</h1>
					<div class="description">
						<TipTapViewer
							v-if="currentLesson.data.description"
							v-model="currentLesson.data.description"
							read-only
							class="mb-5 px-5-mod "
						/>
						<div class="lesson-support-wrapper" v-if="currentLesson.data.digital_assets.length">
							<h3 class="lesson-support-title">{{ $t('Supporting Materials') }}</h3>
							<ul class="supporting-materials-list">
								<li :key="index" v-for="(asset, index) in currentLesson.data.digital_assets">
									<a @click.prevent="downloadFile(asset)">
										<img :src="iconUrl" class="download-icon" />
										<span class="download-text">{{ asset.name }}</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="button-wrapper">
					<!-- Back Button -->
					<div class="controller-button prev-button" @click="prevLesson" v-show="!showHome && canShowPrev">
						<svg width="8" height="15" fill="var(--stan-store-custom-highlight-color)" xmlns="http://www.w3.org/2000/svg" class="">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M7.438.238a.812.812 0 010 1.149L1.704 7.121a.27.27 0 000 .383l5.734 5.734a.812.812 0 11-1.149 1.149L.555 8.653c-.74-.74-.74-1.94 0-2.681L6.29.238a.812.812 0 011.15 0z"
								fill="inherit"
							></path>
						</svg>
						<div class="controller-button-text align-items-start flex-shrink-full">
							<div class="para-3 button-header-text">Previous Lesson:</div>
							<div class="para-3 font-semibold button-subheader-text text-ellipsis">
								{{ previousLessonTitle }}
							</div>
						</div>
					</div>

					<!-- Next -->
					<div class="controller-button next-button" @click="nextLesson" v-show="!showHome && canShowNext">
						<div class="controller-button-text align-items-end flex-shrink-full">
							<div class="para-3 button-header-text">Next Lesson:</div>
							<div class="para-3 font-semibold button-subheader-text text-ellipsis text-right">
								{{ nextLessonTitle }}
							</div>
						</div>
						<svg width="8" height="16" fill="var(--stan-store-custom-highlight-color)" xmlns="http://www.w3.org/2000/svg" class="">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z"
								fill="inherit"
							></path>
						</svg>
					</div>

					<!-- COMPLETE -->
					<div class="controller-button next-button" @click="goToHome()" v-show="!showHome && !canShowNext">
						<div class="controller-button-text align-items-end">
							<div class="para-3 button-header-text">All Done!</div>
							<div class="para-3 font-semibold button-subheader-text text-ellipsis text-right">
								Complete Course
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Sidebar -->
		<CourseSidebar
			:modules="modules"
			:showCoursesList="showCoursesList"
			:course="course"
			:activeModule="currentModule"
			:activeLesson="currentLesson"
			:chooseLesson="goToLesson"
			@chooseHome="goToHome"
			@toggleShowCoursesList="toggleShowCoursesList"
		/>
	</div>
</template>

<script>
	import Color from 'color'
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import Video from '~/stan-vue-shared/components/Video'

	import CONSTANTS from '../../global_helper/constants.js'

	export default {
		name: 'CoursePageDesktopPreview',
		props: {
			user: { type: Object, default: () => {} },
			course: { type: Object, default: () => {} },
			chooseHome: { type: Function, default: () => {} },
			chooseLesson: { type: Function, default: () => {} },
			prevLesson: { type: Function, default: () => {} },
			nextLesson: { type: Function, default: () => {} },
			firstLesson: { type: Function, default: () => {} },
			activeModule: { type: Number, default: 0 },
			activeLesson: { type: Number, default: 0 },
			showHome: { type: Boolean, default: false },
			canShowNext: { type: Boolean, default: false },
			canShowPrev: { type: Boolean, default: false },
			nextLessonTitle: { type: String, default: '' },
			previousLessonTitle: { type: String, default: '' },
			header: { type: Boolean, default: true },
			hideVideo: { type: Boolean, default: true },
		},
		components: {
			TipTapViewer,
			Video,
		},
		mounted() {
			this.logScreenSize()
			window.addEventListener('resize', this.logScreenSize)
		},
		created() {
			this.initializeTheme()
		},
		computed: {
			modules() {
				return this.course.modules
			},
			currentModule() {
				return this.modules[this.activeModule]
			},
			currentLesson() {
				return this.currentModule?.lessons[this.activeLesson]
			},
			lessonHasVideo() {
				return this.currentLesson?.data.video
			},
			bannerImage() {
				return this.course.data?.banner_image_url
			},
			iconUrl() {
				const backgroundColor = this.course?.data?.theme?.background_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_BG
				return new Color(backgroundColor).isDark() ? '/images/icons/ic-digital-download-white.svg' : '/images/icons/ic-digital-download.svg'
			},
		},
		watch: {
			activeModule() {
				this.scrollTop()
			},
			activeLesson() {
				this.scrollTop()
			},
		},
		data() {
			return {
				outlineVisible: true,
				screenSize: 0,
				showCoursesList: true,
			}
		},
		methods: {
			logScreenSize() {
				this.screenSize = window.innerWidth
				if (window.innerWidth > 768) {
					this.showCoursesList = true
				} else if (this.showCoursesList) {
					this.showCoursesList = false
				}
			},
			toggleShowCoursesList() {
				if (this.screenSize <= 768) {
					this.showCoursesList = !this.showCoursesList
				}
			},
			closeShowCoursesList() {
				if (this.screenSize <= 768) {
					this.showCoursesList = false
				}
			},
			goToLesson(_, lesson) {
				this.chooseLesson(lesson.lesson_id)
				this.closeShowCoursesList()
			},
			goToHome() {
				this.chooseHome()
				this.closeShowCoursesList()
			},
			scrollTop() {
				$('.main-wrapper').animate({ scrollTop: 0 }, 0)
			},
			toggleOutline() {
				this.outlineVisible = !this.outlineVisible
			},
			close() {
				this.$emit('close')
			},
			initializeTheme() {
				const backgroundColor = this.course?.data?.theme?.background_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_BG
				const highlightColor = this.course?.data?.theme?.highlight_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_HIGHLIGHT_COLOR
				const titleFont = this.course?.data?.theme?.title_font ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_FONT
				const textColor = new Color(backgroundColor).isDark() ? 'white' : CONSTANTS.THEME_DEFAULTS.DEFAULT_TEXT_COLOR

				const highlightRGB = this.$hexToRGB(highlightColor.replace('#', '') ?? '#5383ff'.replace('#', ''))

				// Different shades of the colours
				const rgbaColor5 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.05})`
				const rgbaColor10 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.1})`
				const rgbaColor25 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.25})`
				const rgbaColor75 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.75})`

				// Set document values
				document.documentElement.style.setProperty('--stan-store-highlight-5-opacity', rgbaColor5)
				document.documentElement.style.setProperty('--stan-store-highlight-10-opacity', rgbaColor10)
				document.documentElement.style.setProperty('--stan-store-highlight-25-opacity', rgbaColor25)
				document.documentElement.style.setProperty('--stan-store-highlight-75-opacity', rgbaColor75)

				document.documentElement.style.setProperty('--stan-store-custom-text-color', textColor)
				document.documentElement.style.setProperty('--stan-store-custom-background-color', backgroundColor)
				document.documentElement.style.setProperty(
					'--stan-store-custom-darkened-background-color',
					new Color(backgroundColor).darken(0.1).hex()
				)
				document.documentElement.style.setProperty('--stan-store-custom-highlight-color', highlightColor)
				document.documentElement.style.setProperty('--stan-store-custom-title-font', titleFont)
			},
			downloadFile(asset) {
				fetch(asset.url)
					.then(response => response.blob())
					.then(blob => {
						const url = window.URL.createObjectURL(blob)
						const a = document.createElement('a')
						a.href = url
						a.download = asset.name // This will set the correct filename
						document.body.appendChild(a)
						a.click()
						a.remove() // Clean up the element after clicking
					})
					.catch(err => console.error(err))
			},
		},
	}
</script>

<style lang="scss" scoped>
	.main-wrapper {
		width: 100%;
		height: 100%;
		background-color: var(--stan-store-custom-background-color);
		position: fixed;
		overflow-y: auto;
		display: flex;
		flex-direction: row;
		top: 0;
		left: 0;
		z-index: 9999;

		.exit-preview-banner {
			font-weight: 600;
			padding: 12px 0px;
			width: 100%;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			background-color: var(--stan-store-highlight-10-opacity);
			color: var(--stan-store-custom-text-color);
		}

		.main-section {
			position: relative;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 30px;
			background-color: var(--stan-store-custom-background-color);
			padding: 30px 50px 0px 50px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	.video-placeholder {
		width: 100%;
		min-height: 200px;
		aspect-ratio: 1.65;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--stan-blue-8);
	}

	.title-text {
		padding: 0 30px;
		color: var(--stan-store-custom-highlight-color);
		font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
		font-size: 1.75rem;
		font-weight: 800;
		word-break: break-word;
		@media (min-width: 768px) {
			padding: 0;
			font-size: 2rem;
		}
		&:first-child {
			margin-top: 30px;
			@media (min-width: 768px) {
				margin-top: 0;
			}
		}
	}

	.description {
		width: 100%;
		padding: 0 30px;
		color: var(--stan-store-custom-text-color);
		@media (min-width: 768px) {
			padding: 0;
		}
		.ProseMirror {
			word-wrap: break-word;
			white-space: break-spaces;
			-webkit-font-variant-ligatures: none;
			font-variant-ligatures: none;
			font-feature-settings: 'liga' 0;
			position: relative;
			height: auto;
			::marker {
				color: var(--stan-store-custom-highlight-color);
			}
			p {
				margin-top: 30px;
				margin-bottom: 0;
				@media (min-width: 768px) {
					margin-top: 40px;
				}
			}
			li {
				p {
					margin-top: 1rem;
					@media (min-width: 768px) {
						margin-top: 1rem;
					}
				}
			}
			ul {
				margin-top: 30px;
				@media (min-width: 768px) {
					margin-top: 40px;
				}
			}
		}
		.lesson-support-wrapper {
			margin-top: 30px;
			.lesson-support-title {
				font-size: 1.3rem;
				font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
				color: var(--stan-store-custom-text-color);
				padding-bottom: 10px;
			}
			.download-icon {
				height: 1.2rem;
				transition: transform 0.3s ease-in-out;
			}
			.download-text {
				transition: all 0.3s ease;
				border-bottom: 1px solid transparent;
				color: var(--stan-store-custom-highlight-color);
			}
			.supporting-materials-list {
				list-style: none;
				padding: 0;
				li {
					a {
						color: $link-blue;
						text-decoration: none;
						position: relative;
						padding-bottom: 2px;

						&:hover {
							filter: brightness(0.8);
							.download-icon {
								animation: bounce 1s ease infinite;
							}
							.download-text {
								border-color: var(--stan-store-custom-highlight-color);
							}
						}
					}
				}
			}
		}
	}
	@keyframes bounce {
		0%,
		100% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(2px);
		}
	}

	.para-3 {
		font-family: 'Inter', sans;
		font-size: 12px;
		line-height: 115%;
	}

	.font-semibold {
		font-weight: 600;
	}

	.button-wrapper {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 26px;
		margin-bottom: 12rem;

		@media (max-width: 768px) {
			grid-template-columns: 1fr;
			padding: 0px 25px 0px 25px;
		}

		.controller-button {
			display: flex;
			flex-direction: row;
			gap: 22px;
			padding: 22px;
			cursor: pointer;
			border-radius: 10px;
			color: var(--stan-store-custom-highlight-color) !important;
			transition: all 0.2s ease;
			align-items: center;

			&.prev-button {
				grid-column: 1;
				background-color: var(--stan-store-highlight-5-opacity) !important;
				color: var(--stan-store-custom-highlight-color) !important;
			}

			&.next-button {
				background-color: var(--stan-store-highlight-10-opacity) !important;
				grid-column: 2;

				&:hover {
					background-color: var(--stan-store-highlight-5-opacity) !important;
				}

				@media (max-width: 768px) {
					grid-column: 1;
				}
			}
		}

		.controller-button-text {
			display: flex;
			flex-direction: column;
			gap: 5px;
			width: 100%;
		}

		.button-header-text {
			color: var(--stan-store-highlight-75-opacity);
		}

		.button-subheader-text {
			color: var(--stan-store-custom-highlight-color);
		}
	}

	.flex-shrink-full {
		flex-shrink: 100;
	}

	.align-items-start {
		align-items: start;
	}

	.align-items-end {
		align-items: end;
	}

	.text-right {
		text-align: right;
	}

	.text-ellipsis {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}

	.lesson-details {
		width: 100%;
		max-width: 700px;
		margin: auto;
	}
</style>

<style lang="scss">
	.description {
		.ProseMirror {
			word-wrap: break-word;
			white-space: break-spaces;
			-webkit-font-variant-ligatures: none;
			font-variant-ligatures: none;
			font-feature-settings: 'liga' 0;
			position: relative;
			height: auto;
			::marker {
				color: var(--stan-store-custom-highlight-color) !important;
			}
		}
	}
</style>
