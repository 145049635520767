import timezones from './TimeZoneList.js'

export const defaultFunnelData = {
	name: 'My Funnel',
	primary_color: '#001D6D',
	secondary_color: '#09BB71',
}

export const defaultPrice = {
	amount: 9.99,
	interval: 'month',
	currency: 'USD',
}

export const meetingPlatformArray = [
	{ value: 'google-calendar', label: 'Google Meet', image: '/images/calendar-icon.svg' },
	{ value: 'zoom', label: 'Zoom Meeting', image: '/images/dd-zoom.svg' },
	{ value: 'phone-email', label: 'Custom Location', image: '/images/icons/ic-camera-gray.svg' },
	{ value: '', label: 'Default', image: '/images/calendar.svg' },
]
export const daysArray = [
	{ value: 1, name: 'Monday' },
	{ value: 2, name: 'Tuesday' },
	{ value: 3, name: 'Wednesday' },
	{ value: 4, name: 'Thursday' },
	{ value: 5, name: 'Friday' },
	{ value: 6, name: 'Saturday' },
	{ value: 7, name: 'Sunday' },
]
export const slotTime = [
	{ value: 15, label: '15 min' },
	{ value: 30, label: '30 min' },
	{ value: 45, label: '45 min' },
	{ value: 60, label: '60 min' },
	{ value: 90, label: '90 min' },
	{ value: 120, label: '120 min' },
	{ value: 150, label: '150 min' },
	{ value: 180, label: '180 min' },
	{ value: 210, label: '210 min' },
	{ value: 240, label: '240 min' },
	{ value: 360, label: '360 min' },
	{ value: 480, label: '480 min' },
]
export const bufferTime = [
	{ value: 5, label: '5 min' },
	{ value: 10, label: '10 min' },
	{ value: 15, label: '15 min' },
	{ value: 30, label: '30 min' },
	{ value: 45, label: '45 min' },
	{ value: 60, label: '1 hr' },
	{ value: 90, label: '1 hr 30 min' },
	{ value: 120, label: '2 hrs' },
	{ value: 150, label: '2 hrs 30 min' },
	{ value: 180, label: '3 hrs' },
]
export const timesArray = [
	{ value: 0, name: '12:00 AM', api_value: '12:00 AM' },
	{ value: 0.25, name: '12:15 AM', api_value: '12:15 AM' },
	{ value: 0.5, name: '12:30 AM', api_value: '12:30 AM' },
	{ value: 0.75, name: '12:45 AM', api_value: '12:45 AM' },
	{ value: 1, name: '1:00 AM', api_value: '01:00 AM' },
	{ value: 1.25, name: '1:15 AM', api_value: '01:15 AM' },
	{ value: 1.5, name: '1:30 AM', api_value: '01:30 AM' },
	{ value: 1.75, name: '1:45 AM', api_value: '01:45 AM' },
	{ value: 2, name: '2:00 AM', api_value: '02:00 AM' },
	{ value: 2.25, name: '2:15 AM', api_value: '02:15 AM' },
	{ value: 2.5, name: '2:30 AM', api_value: '02:30 AM' },
	{ value: 2.75, name: '2:45 AM', api_value: '02:45 AM' },
	{ value: 3, name: '3:00 AM', api_value: '03:00 AM' },
	{ value: 3.25, name: '3:15 AM', api_value: '03:15 AM' },
	{ value: 3.5, name: '3:30 AM', api_value: '03:30 AM' },
	{ value: 3.75, name: '3:45 AM', api_value: '03:45 AM' },
	{ value: 4, name: '4:00 AM', api_value: '04:00 AM' },
	{ value: 4.25, name: '4:15 AM', api_value: '04:15 AM' },
	{ value: 4.5, name: '4:30 AM', api_value: '04:30 AM' },
	{ value: 4.75, name: '4:45 AM', api_value: '04:45 AM' },
	{ value: 5, name: '5:00 AM', api_value: '05:00 AM' },
	{ value: 5.25, name: '5:15 AM', api_value: '05:15 AM' },
	{ value: 5.5, name: '5:30 AM', api_value: '05:30 AM' },
	{ value: 5.75, name: '5:45 AM', api_value: '05:45 AM' },
	{ value: 6, name: '6:00 AM', api_value: '06:00 AM' },
	{ value: 6.25, name: '6:15 AM', api_value: '06:15 AM' },
	{ value: 6.5, name: '6:30 AM', api_value: '06:30 AM' },
	{ value: 6.75, name: '6:45 AM', api_value: '06:45 AM' },
	{ value: 7, name: '7:00 AM', api_value: '07:00 AM' },
	{ value: 7.25, name: '7:15 AM', api_value: '07:15 AM' },
	{ value: 7.5, name: '7:30 AM', api_value: '07:30 AM' },
	{ value: 7.75, name: '7:45 AM', api_value: '07:45 AM' },
	{ value: 8, name: '8:00 AM', api_value: '08:00 AM' },
	{ value: 8.25, name: '8:15 AM', api_value: '08:15 AM' },
	{ value: 8.5, name: '8:30 AM', api_value: '08:30 AM' },
	{ value: 8.75, name: '8:45 AM', api_value: '08:45 AM' },
	{ value: 9, name: '9:00 AM', api_value: '09:00 AM' },
	{ value: 9.25, name: '9:15 AM', api_value: '09:15 AM' },
	{ value: 9.5, name: '9:30 AM', api_value: '09:30 AM' },
	{ value: 9.75, name: '9:45 AM', api_value: '09:45 AM' },
	{ value: 10, name: '10:00 AM', api_value: '10:00 AM' },
	{ value: 10.25, name: '10:15 AM', api_value: '10:15 AM' },
	{ value: 10.5, name: '10:30 AM', api_value: '10:30 AM' },
	{ value: 10.75, name: '10:45 AM', api_value: '10:45 AM' },
	{ value: 11, name: '11:00 AM', api_value: '11:00 AM' },
	{ value: 11.25, name: '11:15 AM', api_value: '11:15 AM' },
	{ value: 11.5, name: '11:30 AM', api_value: '11:30 AM' },
	{ value: 11.75, name: '11:45 AM', api_value: '11:45 AM' },
	{ value: 12, name: '12:00 PM', api_value: '12:00 PM' },
	{ value: 12.25, name: '12:15 PM', api_value: '12:15 PM' },
	{ value: 12.5, name: '12:30 PM', api_value: '12:30 PM' },
	{ value: 12.75, name: '12:45 PM', api_value: '12:45 PM' },
	{ value: 13, name: '1:00 PM', api_value: '01:00 PM' },
	{ value: 13.25, name: '1:15 PM', api_value: '01:15 PM' },
	{ value: 13.5, name: '1:30 PM', api_value: '01:30 PM' },
	{ value: 13.75, name: '1:45 PM', api_value: '01:45 PM' },
	{ value: 14, name: '2:00 PM', api_value: '02:00 PM' },
	{ value: 14.25, name: '2:15 PM', api_value: '02:15 PM' },
	{ value: 14.5, name: '2:30 PM', api_value: '02:30 PM' },
	{ value: 14.75, name: '2:45 PM', api_value: '02:45 PM' },
	{ value: 15, name: '3:00 PM', api_value: '03:00 PM' },
	{ value: 15.25, name: '3:15 PM', api_value: '03:15 PM' },
	{ value: 15.5, name: '3:30 PM', api_value: '03:30 PM' },
	{ value: 15.75, name: '3:45 PM', api_value: '03:45 PM' },
	{ value: 16, name: '4:00 PM', api_value: '04:00 PM' },
	{ value: 16.25, name: '4:15 PM', api_value: '04:15 PM' },
	{ value: 16.5, name: '4:30 PM', api_value: '04:30 PM' },
	{ value: 16.75, name: '4:45 PM', api_value: '04:45 PM' },
	{ value: 17, name: '5:00 PM', api_value: '05:00 PM' },
	{ value: 17.25, name: '5:15 PM', api_value: '05:15 PM' },
	{ value: 17.5, name: '5:30 PM', api_value: '05:30 PM' },
	{ value: 17.75, name: '5:45 PM', api_value: '05:45 PM' },
	{ value: 18, name: '6:00 PM', api_value: '06:00 PM' },
	{ value: 18.25, name: '6:15 PM', api_value: '06:15 PM' },
	{ value: 18.5, name: '6:30 PM', api_value: '06:30 PM' },
	{ value: 18.75, name: '6:45 PM', api_value: '06:45 PM' },
	{ value: 19, name: '7:00 PM', api_value: '07:00 PM' },
	{ value: 19.25, name: '7:15 PM', api_value: '07:15 PM' },
	{ value: 19.5, name: '7:30 PM', api_value: '07:30 PM' },
	{ value: 19.75, name: '7:45 PM', api_value: '07:45 PM' },
	{ value: 20, name: '8:00 PM', api_value: '08:00 PM' },
	{ value: 20.25, name: '8:15 PM', api_value: '08:15 PM' },
	{ value: 20.5, name: '8:30 PM', api_value: '08:30 PM' },
	{ value: 20.75, name: '8:45 PM', api_value: '08:45 PM' },
	{ value: 21, name: '9:00 PM', api_value: '09:00 PM' },
	{ value: 21.25, name: '9:15 PM', api_value: '09:15 PM' },
	{ value: 21.5, name: '9:30 PM', api_value: '09:30 PM' },
	{ value: 21.75, name: '9:45 PM', api_value: '09:45 PM' },
	{ value: 22, name: '10:00 PM', api_value: '10:00 PM' },
	{ value: 22.25, name: '10:15 PM', api_value: '10:15 PM' },
	{ value: 22.5, name: '10:30 PM', api_value: '10:30 PM' },
	{ value: 22.75, name: '10:45 PM', api_value: '10:45 PM' },
	{ value: 23, name: '11:00 PM', api_value: '11:00 PM' },
	{ value: 23.25, name: '11:15 PM', api_value: '11:15 PM' },
	{ value: 23.5, name: '11:30 PM', api_value: '11:30 PM' },
	{ value: 23.75, name: '11:45 PM', api_value: '11:45 PM' },
	{ value: 24, name: '12:00 AM', api_value: '12:00 AM' },
]

export function getTimesValue(val) {
	const id = timesArray.findIndex(tt => tt.api_value === val)
	if (id >= 0) {
		return timesArray[id].value
	}
	return timesArray[0].value
}

export function getTimesAPIValue(val) {
	const id = timesArray.findIndex(tt => tt.value === val)
	if (id >= 0) {
		return timesArray[id].api_value
	}
	return timesArray[0].api_value
}

export function getTimesName(val) {
	const id = timesArray.findIndex(tt => tt.value === val)
	if (id >= 0) {
		return timesArray[id].name
	}
	return timesArray[0].name
}

export function getTimeZonesRawList() {
	function getNameFromText(str) {
		const regex = /\)([A-Za-z\s,.-]+)[+(]*/ // Match the first occurrence of text inside parentheses
		const match = str.match(regex)
		return match ? match[1].trim() : ''
	}

	const tz = []
	for (let i = 0, total = timezones.length; i < total; i++) {
		const timezone = timezones[i]
		const totalUtc = timezone.utc.length
		if (totalUtc === 0) {
			continue
		}
		let showName
		if (timezone.principal_cities.length > 0) {
			showName = timezone.principal_cities.join(', ')
		} else {
			const texts = []
			for (let j = 0; j < totalUtc; j++) {
				const zone = timezone.utc[j].split('/')
				if (zone.length === 2 && zone[0].toLowerCase() !== 'etc') {
					texts.push(zone[1].replace(/_/g, ' '))
				}
			}
			showName = texts.length !== 0 && texts.length <= 4 ? texts.join(', ') : texts.slice(0, 3).join(', ')
			showName = showName.replace(/_/g, ' ')
			if (showName === '') {
				showName = getNameFromText(timezone.text)
			}
		}
		const offset = timezone.offset > 0 ? `+${timezone.offset}` : timezone.offset

		tz.push({
			id: i,
			iso: timezone.abbr,
			offset: timezone.offset,
			zones: timezone.utc,
			value: timezone.value,
			label: `${timezone.abbr} - ${showName} | UTC ${offset}`,
			showName,
			name: timezone.utc[0],
		})
	}
	return tz
}

class TimezoneArray extends Array {
	constructor(args) {
		super(...args)
	}

	find(callbackfn) {
		if (this === null) {
			throw new TypeError('this is null or not defined')
		}

		if (typeof callbackfn !== 'function') {
			throw new TypeError('this is callback not function')
		}
		for (let i = 0, total = this.length; i < total; i += 1) {
			const timezone = this[i]
			for (let j = 0, totalUtc = timezone.zones.length; j < totalUtc; j += 1) {
				const zone = {
					name: timezone.zones[j],
					value: timezone.zones[j], // added to allow autoscroll for dropdowns
				}
				if (callbackfn.call(null, zone, i, this)) {
					return timezone
				}
			}
		}
		return undefined
	}

	filter(callbackfn) {
		if (this === null) {
			throw new TypeError('this is null or not defined')
		}
		if (typeof callbackfn !== 'function') {
			throw new TypeError('this is callback not function')
		}
		const result = []
		const timezoneIds = {}
		for (let i = 0, total = this.length; i < total; i += 1) {
			const timezone = this[i]
			if (callbackfn.call(null, timezone, i, this)) {
				if (!(timezone.id in timezoneIds)) {
					timezoneIds[timezone.id] = 1
					result.push(timezone)
				}
			}
			for (let j = 0, totalUtc = timezone.zones.length; j < totalUtc; j += 1) {
				const zone = { label: timezone.zones[j] }
				if (callbackfn.call(null, zone, i, this)) {
					if (!(timezone.id in timezoneIds)) {
						timezoneIds[timezone.id] = 1
						result.push(timezone)
					}
				}
			}
		}
		return new TimezoneArray(result)
	}

	map(...args) {
		return new TimezoneArray(...super.map.apply(this, args))
	}
}

export const timeZonesRawList = new TimezoneArray(getTimeZonesRawList())

export function timeZonesList() {
	function getUtcOffsetText(str) {
		const regex = /\((.*?)\)/ // Match the first occurrence of text inside parentheses
		const match = str.match(regex)

		if (match) {
			const textInsideParentheses = match[1]
			return `(${textInsideParentheses})`
		}
		return ''
	}
	// eslint-disable-next-line arrow-body-style
	const tz = []
	for (let i = 0; i < timezones.length; i++) {
		const timezone = timezones[i]
		for (let j = 0; j < timezone.utc.length; j++) {
			tz.push({ label: `${getUtcOffsetText(timezone.text)} ${timezone.abbr} - ${timezone.utc[j]}`, name: timezone.utc[j] })
		}
	}
	return tz
}

export function getTimezoneOffset(timeZone) {
	const now = new Date()
	const tzString = now.toLocaleString('en-US', { timeZone })
	const localString = now.toLocaleString('en-US')
	const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000
	const offset = diff + now.getTimezoneOffset() / 60
	return -offset
}

export const discountTypes = {
	PERCENT: 'percent',
	FIXED: 'fixed',
}

export default {
	defaultPrice,
	daysArray,
	timesArray,
	getTimezoneOffset,
}
