var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider-container" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.localValue,
          expression: "localValue"
        }
      ],
      staticClass: "slider",
      attrs: { type: "range", min: _vm.minValue, max: _vm.maxValue },
      domProps: { value: _vm.localValue },
      on: {
        __r: function($event) {
          _vm.localValue = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }