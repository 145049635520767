const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/automations',
	name: 'AutomationsIndex',
	redirect: '/automations',
	component: MainContainer,
	props: true,
	children: [
		{
			path: '',
			name: 'automations',
			component: () => import('./Automations').then(m => m.default || m),
			meta: {
				breadcrumbs: [{ title: 'My AutoDM', active: true }],
			},
		},
	],
}

export default moduleRoutes
