var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "custom-modal", attrs: { id: _vm.id, "aria-hidden": "true" } },
    [
      _c("div", {
        ref: "modalBackdrop",
        staticClass: "popup-backdrop",
        class: { show: _vm.showing, hide: _vm.hideDataBackdrop },
        on: { click: _vm.handleBackdropClick }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "modalContent",
          staticClass: "popup-container modal-content add-section-modal",
          class: { show: _vm.showing, framed: !_vm.frame },
          style: _vm.computedStyle,
          attrs: { "aria-hidden": !_vm.showing }
        },
        [
          !_vm.hideClose
            ? _c("div", { staticClass: "stan-modal-header" }, [
                _c(
                  "div",
                  {
                    staticClass: "close close-modal",
                    attrs: { "aria-label": "Close" },
                    on: { click: _vm.hide }
                  },
                  [
                    _c("InlineSvg", {
                      staticClass: "close-icon",
                      attrs: { src: "/images/icons/ic-close.svg" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex w-100 justify-content-center modal-heading"
            },
            [
              _vm.titleIcon
                ? _c("InlineSvg", {
                    staticClass: "mr-2",
                    attrs: { src: _vm.titleIcon }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.title
                ? _c("h3", { staticClass: "h3 text-bold" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-inner-content",
              class: { "overflow-visible": _vm.overflow }
            },
            [
              _vm.subheading
                ? _c("div", { staticClass: "modal-subheading para-2" }, [
                    _vm._v("\n\t\t\t\t" + _vm._s(_vm.subheading) + "\n\t\t\t")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [_vm._t("default")], 2),
              _vm._v(" "),
              _vm.action
                ? _c(
                    "div",
                    { staticClass: "d-flex flex-column gap v-gap mt-3 pt-1" },
                    [
                      _vm.action
                        ? _c(
                            "AppButton",
                            {
                              attrs: {
                                name: `cta-button-modal-${_vm.id}`,
                                icon: _vm.actionIcon,
                                color: _vm.actionColor,
                                loading: _vm.loading,
                                disabled: _vm.disabled
                              },
                              on: { click: _vm.ctaClick }
                            },
                            [_vm._v(_vm._s(_vm.action))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.secondaryAction
                        ? _c(
                            "AppButton",
                            {
                              staticClass: "p-0 h-auto",
                              attrs: {
                                name: `secondary-cta-button-modal-${_vm.id}`,
                                link: "",
                                loading: _vm.loading,
                                disabled: _vm.disabled,
                                color: _vm.secondaryActionColor
                              },
                              on: { click: _vm.secondaryCtaClick }
                            },
                            [_vm._v(_vm._s(_vm.secondaryAction))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("footer")
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.footerNote.length
                ? _c(
                    "span",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-center mt-3 para-4 text-light text-center"
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.footerNote) + "\n\t\t\t")]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }