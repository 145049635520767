<template>
	<div class="menu-wrapper h-100" :id="`dropdown-wrapper-${id}`" :class="{ rounded }">
		<div
			class="dropdown h-100"
			:class="{ 'position-static': useStaticPosition, rounded }"
			:id="`dropdownMenuLink-${id}`"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			<!-- Use custom component to represent dropdown -->
			<div class="menu-trigger h-100" :class="menuTriggerClass">
				<slot>
					<button class="menu-trigger d-flex flex-wrap justify-content-center align-items-center ml-auto px-2">
						<img src="/images/stan_dropdown_icon.svg" width="3.56" />
					</button>
				</slot>
			</div>
			<div
				class="dropdown-menu menu-content"
				:class="{ 'dropdown-menu-right': rightAlign }"
				:style="{ 'min-width': `${minWidth}px` }"
				:aria-labelledby="`dropdownMenuLink-${id}`"
				ref="dropdown-content"
				id="dropdown-content"
			>
				<div
					v-for="option in options"
					:key="getOptionId(option)"
					class="text-center"
					:class="option.class"
					:id="`option-${sanitizeOptionValueId(getOptionId(option))}`"
				>
					<div class="divider" v-if="option.type === 'divider'"></div>
					<div v-else-if="isUseRowComponent">
						<Component
							:id="`option-${sanitizeOptionValueId(getOptionId(option))}`"
							:is="dropdownRowComponent"
							:option="option"
							@click="selected"
						/>
					</div>
					<button
						v-else
						class="d-flex align-items-center dropdown-item"
						:class="{ 'item-type-tag': option.type === 'tag' }"
						@click="selected(option)"
						:id="`option-${sanitizeOptionValueId(getOptionId(option))}`"
					>
						<div v-if="option.type === 'tag'">
							<AppTag
								:label="option[label]"
								:backgroundColor="option[backgroundColor]"
								:textColor="option[textColor]"
								:image="option[image]"
							/>
						</div>
						<div v-else class="d-flex align-items-center">
							<div v-if="option.image" class="mr-1 col-auto px-0 d-flex">
								<AppImage :src="option.image" :class="{ rounded: roundedIcons }" :circle="circleIcons" cover :width="imageWidth" />
							</div>
							<div class="text-truncate label-text para-2" :class="option.class" :style="option.labelStyle">{{ option[label] }}</div>
						</div>
					</button>
				</div>
				<!-- Use custom dropdown area -->
				<div @click="checkClose">
					<slot name="content"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { UUID } from '~/store/modules/Global.js'

	export default {
		name: 'AppDropdownMenu',
		props: {
			id: {
				type: String,
				default: () => UUID(),
			},
			label: {
				type: String,
				default: 'label',
			},
			backgroundColor: {
				type: String,
				default: 'backgroundColor',
			},
			textColor: {
				type: String,
				default: 'textColor',
			},
			image: {
				type: String,
				default: 'image',
			},
			options: Array,
			circleIcons: Boolean,
			roundedIcons: Boolean,
			imageWidth: {
				type: String,
				default: 'auto',
			},
			keepOpen: Boolean,
			rightAlign: Boolean,
			rounded: Boolean,
			useStaticPosition: Boolean, // use this for dropdown menu overflow issue
			manualControl: Boolean, // this will disable the default behavior of the drodown
			menuTriggerClass: {
				type: String,
				default: '',
			},
			minWidth: {
				type: Number,
				default: 180,
			},
			name: {
				type: String,
				default: '',
			}, // For analytics
			dropdownRowComponent: {
				type: Object,
				default: null,
			},
			dropdownAutoClose: {
				type: Boolean,
				default: true,
			},
			currentValue: {
				type: [Object, String, Number],
			},
		},
		mounted() {
			$(`#dropdown-wrapper-${this.id}`).on('show.bs.dropdown', e => {
				if (this.name) {
					this.$stanAnalytics('admin-show-dropdown', {
						meta: { user_id: this.user?.user_id, username: this.user?.username },
						props: {
							dropdown_menu_name: this.name,
							dropdown_id: this.id,
						},
					})
				}
				this.currentValue !== undefined && this.scrollToCurrent()
				this.$emit('showDropdown', e)
			})
			$(`#dropdown-wrapper-${this.id}`).on('hide.bs.dropdown', e => {
				if (this.name) {
					this.$stanAnalytics('admin-hide-dropdown', {
						meta: { user_id: this.user?.user_id, username: this.user?.username },
						props: {
							dropdown_menu_name: this.name,
							dropdown_id: this.id,
						},
					})
				}
				if (typeof e.clickEvent === 'undefined' && !this.dropdownAutoClose) {
					e.preventDefault()
					return
				}
				this.$emit('hideDropdown', e)
			})
		},
		methods: {
			selected(option) {
				this.$stanAnalytics('admin-dropdown-option-click', {
					meta: { user_id: this.user?.user_id, username: this.user?.username },
					props: {
						dropdown_menu_name: this.name,
						dropdown_id: this.id,
						option_label: option[this.label],
						option_id: option.value,
					},
				})
				this.$emit('selected', option)
			},
			checkClose(e) {
				if (this.keepOpen) {
					this.$emit('bgClick')
					e.stopPropagation()
				}
			},
			toggle(e) {
				if (this.manualControl) {
					if (!this.isShown()) {
						this.show(e)
					} else {
						this.hide(e)
					}
					if (e) e.stopPropagation()
				}
			},
			show(e) {
				if (this.manualControl) {
					if (!this.isShown()) {
						this.$refs['dropdown-content'].classList.add('show')
					}
					if (e) e.stopPropagation()
				}
			},
			hide(e) {
				if (this.manualControl || !this.dropdownAutoClose) {
					if (this.isShown()) {
						this.$refs['dropdown-content'].classList.remove('show')
					}
					if (e) e.stopPropagation()
				}
			},
			isShown() {
				return this.$refs['dropdown-content'].classList.value.indexOf('show') > -1
			},
			close() {
				this.$refs['dropdown-content'].classList.remove('show')
			},
			sanitizeOptionValueId(value) {
				if (!value) return ''

				// Replace all invalid characters for an id with a dash ("-")
				// Allows only alphanumeric characters, underscores, and hyphens
				return String(value).replace(/[^a-zA-Z0-9-_]/g, '-')
			},
			scrollToCurrent() {
				this.$nextTick(() => {
					const dropdownContent = this.$refs['dropdown-content']
					if (!dropdownContent) return

					const selectedOption = this.options.find(option => option.value === this.currentValue)
					if (selectedOption) {
						const selectedElement = dropdownContent.querySelector(
							`#option-${this.sanitizeOptionValueId(this.getOptionId(selectedOption))}`
						)

						if (selectedElement) {
							const elementOffsetTop = selectedElement.offsetTop
							const topGap = 8
							let scrollPosition = elementOffsetTop - topGap
							if (scrollPosition < 0) scrollPosition = 0
							dropdownContent.scrollTop = scrollPosition
						}
					}
				})
			},
			getOptionId(option) {
				return option.id ?? option.value
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			isUseRowComponent() {
				return this.dropdownRowComponent !== null
			},
		},
	}
</script>

<style lang="scss" scoped>
	button {
		height: 40px;
	}

	img.rounded {
		border-radius: 3px;
	}

	.menu-wrapper {
		background-color: transparent !important;
		border: none !important;
		flex: 1 0 0%;
		.menu-trigger {
			all: unset;
			cursor: pointer;
			min-width: 4px;
			height: 28px;

			&.light-gray {
				background-color: var(--stan-gray-soft-color);
			}
		}

		.menu-content {
			padding: 10px;
			z-index: 20;
			border: none;
			background: var(--stan-white);
			box-shadow: var(--stan-box-shadow);
			border-radius: 10px;
			overflow-y: auto;

			.dropdown-item {
				color: var(--stan-text-dark-color);
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				border-radius: 8px;
				&:hover {
					background-color: var(--stan-gray-soft-color);
				}
				.label-text {
					color: var(--stan-text-dark-color);
				}
				img {
					max-width: 100%;
					height: 18px;
					margin-right: 5px;
				}
			}
			.dropdown-item:active {
				background: var(--stan-primary-color);
				color: var(--stan-white);
				&.item-type-tag {
					background: transparent;
				}
			}
		}
	}
	.divider {
		border-top: 1px solid var(--stan-blue-8);
	}
</style>
