<template>
	<div
		class="selectable-card d-flex flex-column justify-content-between"
		:class="{
			active: this.active,
			'md-small-num': this.mdSmallNum,
			'md-big-num': this.mdBigNum,
			selectable: this.selectable,
		}"
		:style="activeCardClass"
		@click="$emit('click')"
	>
		<div v-if="loading" class="loading-skeleton">
			<Skeleton :heading="false" :lines="2" />
		</div>
		<div
			v-if="!loading"
			class="s-card-title h5 d-flex align-items-center flex-wrap"
			:class="{ 'justify-content-between': warningIndicator && warningIndicator.showIndicator }"
		>
			<div class="text-nowrap d-flex align-items-center">
				<InlineSvg :src="icon" class="s-card-icon mr-1" v-if="icon" />
				<span>{{ title }}</span>
				<span v-if="selectable" :style="activeIndicatorClass" class="d-lg-none mx-2"></span>
			</div>

			<AppTooltip v-if="infoTooltipHtml" :click="true" :html="true" :tip="infoTooltipHtml" direction="top" class="ml-2">
				<InlineSvg src="./images/income/help.svg" style="height: 16px; padding: 1px" />
			</AppTooltip>
		</div>
		<div class="s-card-body" v-if="!loading">
			<div class="s-card-number h1 text-bold" :class="{ 'md-small-num': this.mdSmallNum, 'md-big-num': this.mdBigNum }">
				<div class="d-flex" :class="{ 'align-items-center': !alignBottom, 'align-items-end': alignBottom }">
					{{ mainNumPrefix + formattedMainDisplayNumber + mainNumSuffix }}
					<div class="ml-2"><slot></slot></div>
				</div>
			</div>
			<div v-if="!loading && showTrend" class="s-card-percentage" :class="{ 'trend-decrease': trendPercentage < 0 }">
				<InlineSvg class="trend-icon" v-if="trendPercentage < 0" src="/images/icons/ic-decrease.svg" />
				<InlineSvg class="trend-icon" v-else src="/images/icons/ic-increase.svg" />
				<div class="trend-percentage h3" :class="{ decrease: trendPercentage < 0 }">{{ trendPercentageText }}%</div>
			</div>
		</div>
	</div>
</template>

<script>
	import commonHelper from '~/global_helper/helpers.js'
	import Skeleton from '~/components/skeletons/Skeleton'

	export default {
		components: { Skeleton },
		props: {
			activeColor: {
				type: String,
				default: 'var(--stan-positive-primary-color)',
			},
			active: {
				type: Boolean,
				default: false,
			},
			selectable: {
				type: Boolean,
				default: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			loading: {
				type: Boolean,
				default: false,
			},
			icon: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				default: '',
			},
			mainNum: {
				type: Number,
				default: 0,
			},
			mainNumPrefix: {
				type: String,
				default: '',
			},
			mainNumSuffix: {
				type: String,
				default: '',
			},
			mdSmallNum: {
				type: Boolean,
				default: false,
			},
			mdBigNum: {
				type: Boolean,
				default: false,
			},
			showTrend: {
				type: Boolean,
				default: false,
			},
			trendPercentage: {
				type: Number,
				default: 0,
			},
			warningIndicator: {
				type: Object,
				default: () => {},
			},
			warningTooltipText: {
				type: String,
				default: '',
			},
			infoTooltipHtml: {
				type: String,
				default: '',
			},
			alignBottom: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			activeCardClass() {
				if (this.loading) {
					return {}
				}
				return {
					'border-radius': '8px',
					'border-style': 'solid',
					'border-width': '1px',
					'border-color': this.active ? this.activeColor : 'transparent',
				}
			},
			activeIndicatorClass() {
				return {
					display: 'inline-block',
					'border-radius': '50%',
					'border-style': 'solid',
					'border-width': '2px',
					'margin-top': '2px',
					'border-color': this.active ? this.activeColor : 'transparent',
					'background-color': this.active ? this.activeColor : 'transparent',
					height: '16px',
					width: '16px',
				}
			},
			trendPercentageText() {
				return `${commonHelper.formatNumber((this.trendPercentage * 100).toFixed(0))}`
			},
			formattedMainDisplayNumber() {
				return commonHelper.formatNumber(this.mainNum.toFixed(0))
			},
		},
		beforeDestroy() {
			clearInterval(this.loadingInterval)
		},
	}
</script>

<style lang="scss" scoped>
	.selectable-card {
		&.selectable {
			cursor: pointer;
		}
		height: 100%;
		border-color: var(--stan-gray-strike-color);
		padding: 18px 24px;
		position: relative;
		overflow: hidden; // This change took 2 hours to do, LOL!

		@media (max-width: 991px) {
			border-width: 0 !important;
			box-shadow: none !important;
			padding: 16px;
		}

		.s-card-title {
			color: var(--stan-text-light-color);
			letter-spacing: 0.01em;
		}
		.s-card-icon::v-deep {
			width: 18px;
			height: 18px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-text-light-color);
			}
		}
		.s-card-body {
			bottom: 10px;
			display: flex;

			@media (max-width: 991px) {
				flex-wrap: wrap;
			}

			align-items: flex-end;
			padding-top: 8px;
		}
		.s-card-number {
			display: inline-block;
			&.md-small-num {
				@media (max-width: 991px) {
					font-size: 1.5rem;
					line-height: 1.5rem;
				}
			}
			&.md-big-num {
				@media (max-width: 991px) {
					font-size: 2.4rem;
					line-height: 2.4rem;
				}
			}
		}
		.s-card-percentage {
			margin-left: 8px;
			display: flex;
			align-items: end;
			&.md-small-num {
				@media (max-width: 991px) {
					padding: 4px;
				}
			}
			&.md-big-num {
				@media (max-width: 991px) {
					padding: 10px;
				}
			}
			.trend-percentage {
				color: var(--stan-text-dark-color);
				font-size: 1.125rem;
				font-weight: 500;
				letter-spacing: 0.01em;
			}
			&::v-deep {
				.trend-icon {
					height: 20px;
					width: 20px;
					margin-right: 4px;
					path {
						fill: var(--stan-text-positive-color);
					}
				}
				&.trend-decrease .trend-icon {
					path {
						fill: var(--stan-text-light-color);
					}
				}
			}
		}
		&.active {
			border-color: var(--stan-positive-primary-color);
		}

		.warning-indicator {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			margin-right: 8px;
			&.indicator-healthy {
				background-color: var(--stan-text-positive-color);
			}
			&.indicator-at-risk {
				background-color: var(--stan-text-negative-color);
			}
		}
	}
</style>
