<template>
	<div class="wrapper" :class="`route-${route_name}`" v-if="user">
		<LeftMenu ref="leftMenu" />
		<!-- Right Panel -->
		<div class="rightPanel">
			<div class="d-flex flex-column">
				<AccountDisableHeader v-if="user.status === 0" />
				<NavigationTop ref="form" />
				<WarningHeader class="mt-3 mx-3" v-if="!hasActiveSubscription">
					Your store isn't live yet. No worries! You can renew your subscription&nbsp;<a target="_self" href="/subscribe">here</a>.
				</WarningHeader>
				<WarningHeader class="mt-3 mx-3" v-if="showStripeSetupBanner">
					Heads up, customers can't purchase from you yet! Please
					<a target="_self" href="/account_settings/?tab=store">set up your Direct Deposit</a>
					to start selling
				</WarningHeader>
			</div>
			<div id="main-container" ref="content" class="content d-flex flex-column justify-content-between">
				<RouterView />
			</div>
		</div>
		<!-- Pre Load animations -->
		<AppLottie name="store_loading" cacheAnimation />
		<AppLottie name="phone_width_sparkles" cacheAnimation />

		<ProductFeedbackModal />
		<PlayVideoModal />
		<ChangePlanModal />
		<StartTrialModal />
		<EnterStoreFullScreenModal ref="enterStoreFullScreen" skipFadeIn />
	</div>
	<div v-else class="h-100 w-100">
		<HollowDotsSpinner :animation-duration="1000" :dot-size="18" class="h-100 d-flex align-items-center mx-auto" :dots-num="3" color="#3a36db" />
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'
	import Cookies from 'js-cookie'
	import userflow from '~/plugins/userflow.js'
	import NavigationTop from '~/components/navigation/NavigationTop'
	import LeftMenu from '~/components/navigation/LeftMenu'
	import ProductFeedbackModal from '~/components/modals/ProductFeedbackModal'
	import AccountDisableHeader from '~/components/headers/AccountDisableHeader'
	import PlayVideoModal from '~/components/PlayVideoModal'
	import ChangePlanModal from '~/pages/auth/ChangePlanModal/ChangePlanModal'
	import StartTrialModal from '~/pages/auth/StartTrialModal'
	import EnterStoreFullScreenModal from '~/components/modals/EnterStoreFullScreenModal'

	export default {
		middleware: ['auth', 'subscription-check', 'experiment-setup'],
		components: {
			NavigationTop,
			LeftMenu,
			ProductFeedbackModal,
			AccountDisableHeader,
			PlayVideoModal,
			ChangePlanModal,
			StartTrialModal,
			EnterStoreFullScreenModal,
		},
		props: {
			fromFullScreen: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isActive: 'Dashboard',
				route_name: '',
				settings: {
					maxScrollbarLength: 60,
				},
				deviceType: '',
				isInitialStatusLoaded: false,
				previousScrollY: 0,
				getUserTimer: null,
				userflow: null,
			}
		},
		mounted() {
			// We want to show the loading animation to help give context when coming into the store from a full screen context
			this.checkAnimateFullScreenEnter()
			// Fast Loading of Component Data
			this.fetchTransactions({ page: 1 }) // Important to match what's in Orders.vue to achieve cached loading
			this.fetchCustomers({ page: 1 })
			this.fetchBalance()
			this.fetchRevenueChart()
			this.fetchEmailTemplates()
			this.fetchResellProducts()

			if (this.isAllowedStanPayment) {
				this.fetchStanPayment().then(() => {
					this.fetchStripeConnected().then(() => {
						this.isInitialStatusLoaded = true
					})
				})
			} else {
				this.fetchStripeConnected().then(() => {
					this.isInitialStatusLoaded = true
				})
			}

			if (Cookies.get('language_code')) {
				this.$stanAnalytics('admin_user_language_code', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: { language_code: Cookies.get('language_code') },
				})
			}
		},
		created() {
			this.route_name = this.$router.currentRoute.name
			this.isActive = this.route_name
			if (this.user && !Cookies.get('logon_event')) {
				this.$stanAnalytics('logon', { meta: { user_id: this.user.user_id, username: this.user.username } })
			}
			// ^^ Auto Extend the Cookie time to 15 min
			const in15Minutes = 1 / 24 / 4 // js-cookies uses days as base unit
			Cookies.set('logon_event', '1', { expires: in15Minutes })
			if (userflow && !this.isAdminLoggedInAsUser && !this.isAdmin) {
				// Userflow Initialization
				userflow.InitializeUserflow()
				userflow.UpdateUserflowIdentityData(this.user)

				this.userflow = userflow
				this.checkUpdateUserFlowData()
			}
			this.fetchStore()
			this.fetchFunnels()
			this.fetchFlows()
			this.fetchCommunities({ configSettings: this.$configSettings })
			this.$store.dispatch('Auth/getPlatformSubscription')
		},
		computed: {
			...mapGetters('Global', ['showNavigation', 'showPreview']),
			...mapGetters('Auth', ['user', 'isAdminLoggedInAsUser', 'isAdmin', 'hasActiveSubscription']),
			...mapGetters('Stores', ['getStore', 'getStoreAllPages']),
			...mapGetters('SiteSettingsStore', ['isStripeConnected', 'isPaypalConnected']),
			isAllowedStanPayment() {
				return this.user.experiments?.stan_payment_service || this.user.experiments?.stan_payment_service_reboot
			},
			hasPaidProductsNoDirectDeposit() {
				let hasPaidProduct = false
				for (const page of this.getStore?.pages || []) {
					if (this.isPaidProduct(page.data.product)) {
						hasPaidProduct = true
						break
					}
				}
				return hasPaidProduct && this.user.payment_restricted.linksite
			},
			hasStripeSetupBannerExperiment() {
				return this.user.experiments.stripe_setup_banner
			},
			showStripeSetupBanner() {
				return (
					this.hasActiveSubscription &&
					this.hasPaidProductsNoDirectDeposit &&
					this.hasStripeSetupBannerExperiment &&
					// if user has onboarding, only show banner if past 2nd step + not on dashboard page (dashboard page already shows setup direct deposit)
					(!this.hasOnboardingDashboard ||
						(this.user['onboarding-dashboard']?.profile_image_uploaded &&
							this.user['onboarding-dashboard']?.social_links_added &&
							this.route_name !== 'dashboard'))
				)
			},

			hasOnboardingDashboard() {
				return this.user['onboarding-dashboard']?.status === 'active'
			},
		},
		watch: {
			showNavigation(newVal) {
				const self = this
				if (newVal) {
					this.previousScrollY = window.scrollY
					$('body').addClass('menu-active')
				} else {
					$('body').removeClass('menu-active')
					setTimeout(() => self.$refs.content.scrollTo({ top: this.previousScrollY, behavior: 'smooth' }), 10)
				}
			},
			showPreview(newVal) {
				const self = this
				if (newVal) {
					this.previousScrollY = window.scrollY
					$('body').addClass('preview-active')
				} else {
					$('body').removeClass('preview-active')
					setTimeout(() => self.$refs.content.scrollTo({ top: this.previousScrollY, behavior: 'smooth' }), 10)
				}
			},
			$route(newVal) {
				const self = this
				this.route_name = newVal.name
				if (newVal.params.scroll !== false) {
					setTimeout(() => self.$refs.content.scrollTo({ top: 0 }), 100)
				}
			},
			user(newUser) {
				if (newUser && window.Beacon) {
					window.Beacon('identify', {
						name: newUser.full_name,
						email: newUser.email,
						avatar: newUser.profile_image,
					})
				}
			},
			'user.profile_image': {
				handler() {
					if (this.userflow) {
						userflow.UpdateUserflowIdentityData(this.user)
					}
				},
			},
			isStripeConnected(newVal) {
				if (this.userflow && (newVal === true || newVal === false)) {
					this.checkUpdateUserFlowData()
				}
			},
			isPaypalConnected(newVal) {
				if (this.userflow && (newVal === true || newVal === false)) {
					this.checkUpdateUserFlowData()
				}
			},
			getStoreAllPages(newVal) {
				if (this.userflow && newVal) {
					this.checkUpdateUserFlowData()
				}
			},
			fromFullScreen: {
				handler(newVal) {
					// This is to trigger the entering store transition animation when coming from an existing full screen page that already has the main container loaded
					if (newVal) {
						this.checkAnimateFullScreenEnter()
					}
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions('Stores', ['fetchStore', 'fetchFunnels', 'fetchEmailTemplates']),
			...mapActions('SiteSettingsStore', ['fetchStripeConnected', 'fetchStanPayment']),
			...mapActions('Flows', ['fetchFlows']),
			...mapActions('Transactions', ['fetchTransactions', 'fetchBalance', 'fetchRevenueChart']),
			...mapActions('ResellRights', ['fetchResellProducts']),
			...mapActions('Audience', ['fetchCustomers']),
			...mapActions('Communities', ['fetchCommunities']),
			activeTab(tabName, query, params) {
				this.$router.push({ name: tabName, query, params }).catch(() => {})
			},
			isPaidProduct(product) {
				let price = 0
				if (product.price) price = product.price.sale_amount ? product.price.sale_amount : product.price.amount
				return price > 0
			},
			checkUpdateUserFlowData() {
				if (!this.userflow) {
					return
				}

				// Assign Sections experiment to userflow
				const obj = {}
				for (const experiment in this.user.experiments) {
					obj[experiment] = this.user.experiments[experiment]
				}

				if (this.user.metadata) {
					// Assign Metadata to userflow
					for (const m in this.user.metadata) {
						obj[m] = this.user.metadata[m]
					}
				}

				if (this.isStripeConnected === true || this.isStripeConnected === false) {
					obj.is_stripe_connected = this.isStripeConnected
				}

				if (this.isPaypalConnected === true || this.isPaypalConnected === false) {
					obj.is_paypal_connected = this.isPaypalConnected
				}

				if (this.getStoreAllPages) {
					// Only pages that are published
					const allPublishedProducts = this.getStoreAllPages.filter(f => f.status === 2)
					obj.all_published_products_count = allPublishedProducts.length
					obj.all_published_paid_products_count = allPublishedProducts.filter(f => this.isPaidProduct(f.data.product)).length
				}

				if (obj) {
					this.userflow.UserflowCustomBulkUpdate(obj)
				}
			},
			checkAnimateFullScreenEnter() {
				if (this.fromFullScreen) {
					this.$refs.enterStoreFullScreen.show()
					setTimeout(() => {
						this.$refs.enterStoreFullScreen.hide()
					}, 2500)
				}
			},
		},
		destroyed() {
			if (this.getUserTimer) {
				clearTimeout(this.getUserTimer)
			}
		},
	}
</script>
<style lang="scss" scoped>
	.wrapper {
		display: flex;
		transition: all ease 0.3s;
		height: 100%;
		overflow: hidden;
	}

	.rightPanel {
		width: 100%;
		transition: all ease 0.3s;
		display: flex;
		flex-direction: column;
		padding-bottom: 74px;

		height: 100vh;
		/* If dvh is supported, apply it */
		@supports (height: 100dvh) {
			height: 100dvh;
		}

		@media (min-width: 992px) {
			padding-bottom: 0px;
			padding-left: 220px;
		}

		.content {
			--padding-right-panel-top: 24px;
			flex-basis: 0;
			flex-grow: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			padding: 24px;
			padding-top: var(--padding-right-panel-top);
			position: relative;
			@media (min-width: 992px) {
				--padding-right-panel-top: 35px;
				padding: var(--padding-right-panel-top) 40px 40px;
			}

			@media (max-width: 768px) {
				padding-inline: 16px;
			}
		}
	}
	.preview-active .rightPanel {
		display: none;
		@media (min-width: 992px) {
			display: block;
		}
	}
	.menu-active .rightPanel {
		display: none;
		@media (min-width: 992px) {
			display: block;
		}
	}

	@media (max-width: 767px) {
		.flex-xs-column-reverse {
			flex-direction: column-reverse !important;
		}
		.xs-d-flex {
			display: flex !important;
		}
		.payment-alert {
			margin-bottom: 10px;
		}
	}
	.logo {
		margin: auto;
		padding: 15px;
		@media (min-width: 768px) {
			padding-top: 0;
		}
		@media (min-width: 992px) {
			display: none;
		}
	}
</style>
<style lang="scss">
	.BeaconContainer {
		@media (max-width: 991px) {
			bottom: 90px !important;
		}
	}
	.BeaconFabButtonFrame {
		@media (max-width: 991px) {
			bottom: 90px !important;
		}
	}
</style>
