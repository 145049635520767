import axios from 'axios'
import { configSettings } from '~/plugins/config-settings.js'
import constants from '~/global_helper/constants.js'

export const state = {
	communities: undefined,
}

// Users can only have 1 community for now so this works
export const getters = {
	getCommunities: state => state.communities,
	community: state => state.communities && state.communities.length && state.communities[0],
	communityStatus: state => {
		const community = getters.community(state)
		if (community && constants.COMMUNITY_STATUS.ACTIVE) {
			return community?.status
		}
		return undefined
	},
	communityIsActive: state => {
		const communityStatus = getters.communityStatus(state)
		return communityStatus === constants.COMMUNITY_STATUS.ACTIVE
	},
	communityName: () => {
		const community = getters.community(state)
		if (community) {
			return community?.data?.name
		}
		return undefined
	},
}

export const mutations = {
	setCommunities(state, payload) {
		state.communities = payload
	},
}

export const actions = {
	createCommunity() {
		return new Promise((resolve, reject) => {
			axios
				.post(
					'v1/communities',
					{},
					{
						baseURL: configSettings.communityApiUrl,
					}
				)
				.then(res => {
					resolve(res)
				})
				.catch(error => {
					console.error(error)
					reject(error)
				})
		})
	},
	fetchCommunities({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get('v1/communities/owned', {
					baseURL: configSettings.communityApiUrl,
					params: {
						with_messages: false,
					},
				})
				.then(response => {
					const { communities } = response.data
					commit('setCommunities', communities)
					resolve(communities)
				})
				.catch(error => {
					console.error(error)
					reject(error)
				})
		})
	},
	updateCommunity({ dispatch }, { configSettings, communitySlug, communityData }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`v1/communities/${communitySlug}`, communityData, {
					baseURL: configSettings.communityApiUrl,
				})
				.then(response => {
					const { community } = response.data
					dispatch('fetchCommunities', { configSettings })
					resolve(community)
				})
				.catch(error => {
					console.error(error)
					reject(error)
				})
		})
	},
}
