import { render, staticRenderFns } from "./FileDropZone.vue?vue&type=template&id=40b9aaca&scoped=true"
import script from "./FileDropZone.vue?vue&type=script&lang=js"
export * from "./FileDropZone.vue?vue&type=script&lang=js"
import style0 from "./FileDropZone.vue?vue&type=style&index=0&id=40b9aaca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b9aaca",
  null
  
)

export default component.exports