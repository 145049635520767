const CONSTANTS = {
	ORDER_FILTERS: {
		CASH_OUT_ID: -1,
	},
	PAYMENT_STATUS: {
		PAYMENT_INITIALISED: 0,
		CREATOR_FULFILLED: 1,
		PAID_NOT_FULFILLED: 2,
		REFUNDED: 3,
		DISPUTED: 4,
		FAILED: 5,
		PENDING: 6,
	},
	BOOKING_TYPES: {
		ONE: 'one-on-one',
		GROUP: 'group',
		WEBINAR: 'webinar',
	},
	TIER: {
		CREATOR_PRO: 'creator-pro',
		CREATOR: 'creator',
		AFFILIATE: 'affiliate',
	},
	TIER_INTERVAL: {
		YEAR: 'year',
		MONTH: 'month',
	},
	PAYMENT_TYPES: {
		PAYOUT: 'payout',
		REFERRAL_FEE: 'referral-fee',
		AFFILIATE_FEE: 'affiliate-fee',
		TOPUP: 'topup',
		ACCOUNT_CHARGE: 'account-charge',
	},
	STRIPE_DISPUTE_STATUS: {
		NEEDS_RESPONSE: 'needs_response',
		UNDER_REVIEW: 'under_review',
		LOST: 'lost',
		WARNING_NEEDS_RESPONSE: 'warning_needs_response',
		WARNING_UNDER_REVIEW: 'warning_under_review',
		WON: 'won',
	},
	STRIPE_EVENT_TYPES: {
		PAYMENT_EXPECTED_AVAILABLE_TIME: {
			STRIPE_CODE: 'payment_intent.expected_available_time',
			ICON_WAITING: 'waiting',
			ICON_SUCCESS: 'success',
		},
		PAYMENT_SUCCEEDED: {
			STRIPE_CODE: 'payment_intent.succeeded',
			ICON: 'success',
		},
		PAYOUT_CREATED: {
			STRIPE_CODE: 'payout.created',
			ICON: 'success',
		},
		PAYOUT_UPDATED: {
			STRIPE_CODE: 'payout.updated',
			ICON: 'success',
		},
		PAYOUT_PAID: {
			STRIPE_CODE: 'payout.paid',
			ICON: 'success',
		},
		PAYOUT_FAILED: {
			STRIPE_CODE: 'payout.failed',
			ICON: 'failure',
		},
	},
	// Custom error messages made to handle these stripe payout errors - https://stripe.com/docs/api/payouts/object#payout_object-failure_code
	// Apr 9, 2024: Added more (undocumented) errors based on what we have been getting from Stripe.
	STRIPE_PAYOUT_ERRORS_AND_RESPONSES_HTML: {
		ACCOUNT_CLOSED:
			'There seems to have been an issue with your bank account. Please try contacting your bank to resolve any issues and try again.',
		ACCOUNT_FROZEN:
			'There seems to have been an issue with your bank account. Please try contacting your bank to resolve any issues and try again.',
		BANK_ACCOUNT_RESTRICTED:
			'There seems to have been an issue with your bank account. Please try contacting your bank to resolve any issues and try again.',
		BANK_OWNERSHIP_CHANGED:
			'There seems to have been an issue with your bank account. Please try contacting your bank to resolve any issues and try again.',
		COULD_NOT_PROCESS:
			'There was an issue verifying your bank details. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		DEBIT_NOT_AUTHORIZED: 'There was an issue verifying your bank details. Please try contacting your bank to resolve any issues and try again.',
		DECLINED: 'Your bank has declined the payout. Please try contacting your bank to resolve any issues and try again.',
		INSUFFICIENT_FUNDS:
			'Stripe is having trouble processing this payout. Please reach out to <span><a href="mailto:friends@stanwith.me" >friends@stanwith.me</a></span> so we can look into this for you.',
		INVALID_ACCOUNT_NUMBER:
			'There was an issue verifying your bank details. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		INCORRECT_ACCOUNT_HOLDER_DETAILS:
			'There was an issue verifying your bank details. Please try contacting your bank to resolve any issues and try again.',
		INCORRECT_ACCOUNT_HOLDER_NAME:
			'There was an issue verifying your bank details. Please try contacting your bank to resolve any issues and try again.',
		INCORRECT_ACCOUNT_HOLDER_ADDRESS:
			'There was an issue verifying your bank details. Please try contacting your bank to resolve any issues and try again.',
		INCORRECT_ACCOUNT_HOLDER_TAX_ID:
			'There was an issue verifying your bank details. Please try contacting your bank to resolve any issues and try again.',
		INCORRECT_ACCOUNT_TYPE:
			'The account type that we have on file is incorrect. The account type is usually checking or savings. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		INVALID_CURRENCY:
			'There was an issue verifying your bank details. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		NO_ACCOUNT:
			'There was an issue verifying your bank details. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		UNSUPPORTED_CARD:
			'The card on file no longer supports payouts. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		EXPIRED_CARD:
			'There was an issue verifying your card details. Please try updating your bank details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		EXCEEDS_AMOUNT_LIMIT:
			'There was an issue processing this payout. Please reach out to <span><a href="mailto:friends@stanwith.me" >friends@stanwith.me</a></span> so we can look into this for you.',
		LOST_OR_STOLEN_CARD:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		INVALID_CARD:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		REFER_TO_CARD_ISSUER:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		ISSUER_UNAVAILABLE:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		INVALID_ISSUER:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
		INVALID_TRANSACTION:
			'There was an issue verifying your card details. Please try updating your card details <span><a target="_self" href="/account_settings/?tab=store">here</a></span> and try again.',
	},
	PAYMENT_DISCOUNTS: {
		FREE_FOREVER: "Congrats on your free Stan account - it's completely on us!",
	},
	GLOBAL_EVENTS: {
		AUTO_SAVE_RESTORE_MODAL_SHOW: 'auto-save-restore-show-modal',
		AUTO_SAVE_RESTORE: 'auto-save-restore',
		AUTO_SAVE_NO_ACTION: 'auto-save-no-action',
		AUTO_SAVE_START: 'auto-save-start',
	},
	PLAN_COSTS: {
		CREATOR_MONTH: 29,
		CREATOR_PRO_MONTH: 99,
		CREATOR_ANNUAL: 25,
		CREATOR_PRO_ANNUAL: 79,
	},
	FLOW_STATUS: {
		DELETED: 'deleted',
		DRAFT: 'draft',
		PUBLISHED: 'published',
	},
	FLOW_TYPE: {
		EVENT_TRIGGERED: 'event-triggered',
		USER_TRIGGERED: 'user-triggered',
	},
	FLOW_TRIGGER: {
		CHECKOUT: 'checkout',
		TIME: 'time',
	},
	TAX_BEHAVIOUR: {
		INCLUSIVE: 'inclusive',
		EXCLUSIVE: 'exclusive',
	},
	MENUITEMS: [
		{
			id: 'home',
			title: 'Home',
			icon: 'ic-home',
			route: 'dashboard',
			routeName: 'DashboardIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'menu',
		},
		{
			id: 'linksites',
			title: 'My Store',
			icon: 'ic-store',
			route: 'linksites',
			routeName: 'StoresSectionIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: true,
			mobileOrder: 2,
			type: 'menu',
		},
		{
			id: 'income',
			title: 'Income',
			icon: 'ic-wallet',
			route: 'income',
			routeName: 'IncomeIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: true,
			mobileOrder: 1,
			type: 'menu',
			moduleName: 'income-tab',
		},
		{
			id: 'analytics',
			title: 'Analytics',
			icon: 'ic-line-chart',
			route: 'analytics',
			routeName: 'AnalyticsIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: true,
			mobileOrder: 3,
			type: 'menu',
		},
		{
			id: 'audience',
			title: 'Customers',
			icon: 'ic-heart',
			route: 'audience',
			routeName: 'audienceIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'menu',
			mobileType: 'app',
		},
		{
			id: 'funnels',
			title: 'Funnels',
			subTitle: 'I want to link my pages together!',
			icon: 'ic-funnel',
			route: 'funnels',
			routeName: 'FunnelsSectionIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'app',
		},
		{
			id: 'affiliate',
			title: 'Affiliate',
			subTitle: 'I want to resell available products!',
			icon: 'ic-link',
			route: 'affiliate',
			routeName: 'AffiliateIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'app',
			moduleName: 'affiliate-tab',
		},
		{
			id: 'appointments',
			title: 'Appointments',
			subTitle: 'I want to keep track of my meetings!',
			icon: 'ic-clock',
			route: 'appointments',
			routeName: 'BookingIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'app',
		},
		{
			id: 'referrals',
			title: 'Referrals',
			subTitle: 'I want to make passive income on Stan!',
			icon: 'ic-smile',
			route: 'referrals',
			routeName: 'ReferralsIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'app',
		},
		{
			id: 'email_flow',
			title: 'Email Flows',
			subTitle: 'I want to send automatic emails!',
			icon: 'ic-mail',
			route: 'flows',
			routeName: 'FlowsIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'app',
		},
		{
			id: 'automations',
			title: 'AutoDM',
			subTitle: 'I want to send automatic IG replies!',
			icon: 'ic-send',
			route: 'automations',
			routeName: 'AutomationsIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: false,
			type: 'menu',
			mobileType: 'app',
		},
		{
			id: 'settings',
			title: 'Settings',
			icon: 'ic-settings',
			route: 'account_settings',
			routeName: 'AccountSettingsIndex',
			roles: [1, 2],
			position: 'bottom',
			mobileAction: false,
			type: 'menu',
		},
		{
			id: 'ask_stanley',
			title: 'Get Help',
			icon: 'ic-ask-stanley',
			route: 'ask_stanley',
			routeName: 'AskStanleyIndex',
			roles: [1, 2],
			position: 'bottom',
			mobileAction: false,
			type: 'menu',
		},
		{
			id: 'creator',
			title: 'Creator',
			subTitle: 'View creator accounts',
			icon: 'ic-person',
			route: 'creator',
			routeName: 'CustomerIndex',
			roles: [1],
			position: 'top',
			mobileAction: false,
			type: 'admin',
		},
	],
	AFFILIATE_MENUITEMS: [
		{
			id: 'affiliate',
			title: 'Affiliate',
			subTitle: 'I want to resell available products!',
			icon: 'ic-link',
			route: 'affiliate',
			routeName: 'AffiliateIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: true,
			type: 'menu',
			moduleName: 'affiliate-tab',
		},
		{
			id: 'income',
			title: 'Income',
			icon: 'ic-wallet',
			route: 'income',
			routeName: 'IncomeIndex',
			roles: [1, 2],
			position: 'top',
			mobileAction: true,
			mobileOrder: 1,
			type: 'menu',
			moduleName: 'income-tab',
		},
		{
			id: 'settings',
			title: 'Settings',
			icon: 'ic-settings',
			route: 'account_settings',
			routeName: 'AccountSettingsIndex',
			roles: [1, 2],
			position: 'bottom',
			mobileAction: false,
			type: 'menu',
		},
	],
	INTEGRATION_STATUS: {
		CANCELLED: 0,
		ACTIVE: 1,
		ERRORED: 2,
	},
	INTEGRATION_TYPE: {
		GOOGLE_CALENDAR: 'google-calendar',
		ZOOM: 'zoom',
		STRIPE_CONNECT: 'stripe-connect',
		PAYPAL: 'paypal',
		ZAPIER: 'zapier',
		FACEBOOK: 'facebook',
		INSTAGRAM: 'instagram',
		STAN_PAYMENT: 'stan-payment',
	},
	GENERIC_STATUS: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
		DELETED: 'deleted',
	},
	CHART_TYPES: {
		REVENUE: 'revenue',
		CUMULATIVE_REVENUE: 'cumulative-revenue',
		PAGE_TRAFFIC_BREAKDOWN: 'page-traffic-breakdown',
		COMPLEX_PAGE_TRAFFIC_BREAKDOWN: 'complex-page-traffic-breakdown',
		STORE_BUTTON_CLICK: 'store-button-click',
		PAGE_CONVERSION: 'page-conversion',
		VIEWS: 'views',
		TRAFFIC_SOURCES: 'traffic-sources',
		LEADS: 'leads',
		REFERRALS: 'referrals',
		ORDERS: 'orders',
	},
	MINIMUM_ALLOWED_BALANCE: -50, // in dollars
	PAYMENT_METHOD_TYPES: {
		CARD: 'card',
		KLARNA: 'klarna',
		AFTERPAY: 'afterpay_clearpay',
		IDEAL: 'ideal',
		PAYPAL: 'paypal',
		LINK: 'link',
	},
	DATE_FORMAT: {
		ISO_8601_DATE: 'yyyy-MM-dd',
		USER_DATE: 'MMM d, yyyy',
		DATE_TIME_FORMAT_AM_PM: 'yyyy-MM-dd hh:mm aa',
	},
	STRIPE_ACCOUNT_STATUS: {
		REJECTED: 'rejected',
		RESTRICTED: 'restricted',
		RESTRICTED_SOON: 'restricted-soon',
		INACTIVE: 'inactive',
	},
	LOCAL_STORAGE_KEYS: {
		INTEGRATION_IN_PROGRESS: 'INTEGRATION_IN_PROGRESS',
	},
	PUBLIC_RESOURCES: {
		DEFAULT_REVIEW_IMAGE: 'https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg',
	},
	COURSE_STATUS: {
		PUBLISHED: 1,
		DRAFT: 2,
	},
	COURSE_MODULE_STATUS: {
		PUBLISHED: 1,
		DRAFT: 2,
		DRIP: 3,
	},
	COMMUNITY_STATUS: {
		ACTIVE: 'active',
		INACTIVE: 'inactive',
	},
	PRODUCT_TYPES: {
		DIGITAL_DOWNLOAD: 'digital-download',
		LEAD_MAGNET: 'lead',
		BOOKING: 'booking',
		FULFILLMENT: 'fulfillment',
		MEMBERSHIP: 'membership',
		COURSE: 'course',
		WEBINAR: 'webinar',
		COMMUNITY: 'community',
		LINK: 'link',
	},
	PAGE_DEFAULTS: {
		default: { icon: 'funnel-default', bottom_title: 'My page' },
		'digital-download': { icon: 'funnel-sell-something', bottom_title: 'My sell digital page ' },
		'lead-magnet': { icon: 'funnel-lead-magnet', bottom_title: 'My lead magnet page' },
		meeting: { icon: 'calendar', bottom_title: 'My meeting page' },
		fulfillment: { icon: 'services', bottom_title: 'Sell a Service' },
		membership: { icon: 'membership', bottom_title: 'Recurring Membership' },
		link: { icon: 'link', bottom_title: 'External link / URL' },
		'stan-referral-link': { icon: 'link', bottom_title: 'Stan Affiliate Link' },
		course: { icon: 'course', bottom_title: 'My Course' },
		webinar: { icon: 'webinar', bottom_title: 'My Webinar' },
		community: { icon: 'community', bottom_title: 'My Exclusive Community' },
	},

	THEME_DEFAULTS: {
		DEFAULT_BG: '#f3f6fd',
		DEFAULT_HIGHLIGHT_COLOR: '#5383ff',
		DEFAULT_FONT: 'Grobek',
		DEFAULT_TEXT_COLOR: '#131f60',
	},
	GOOGLE_AD_CONVERSION_ID: 'AW-10854378575',
	GOOGLE_GA4_MEASUREMENT_ID: 'G-FPXVPVPGVK',
	GOOGLE_TAG_MANAGER_ID: 'GTM-W9MXN8NB',
	GOOGLE_AD_CONV_ACTION: {
		SIGN_UP: '',
		ADD_TO_CART: '',
		PURCHASE: 'AW-10854378575/R5K_CPads94ZEM_Y4rco',
	},
	MEDIA_CONTENT_MAP: {
		tiktok: { title: 'TikTok', image: '' },
		spotify: { title: 'Spotify', image: '' },
		youtube: { title: 'YouTube', image: '' },
		loom: { title: 'Loom', image: '' },
		wistia: { title: 'Wistia', image: '' },
		'podcast-apple': { title: 'Apple Podcast', image: '' },
		instagram: { title: 'Instagram', image: '' },
		default: { title: 'Media Content', image: '' },
	},
	URL_TO_EMBED_REGEX: {
		// eslint-disable-next-line no-useless-escape
		youtube: /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch)(?:\/|(?:\?v=))|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
		loom: /https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/,
		wistia: /(?:https?:\/\/)?(?:www\.)?(?:wistia\.com\/medias\/|fast\.wistia\.net\/embed\/medias\/)([a-zA-Z0-9]+)(?:[?&].*)?/,
		tiktok: /(?:https?:\/\/)?(?:www\.)?tiktok\.com\//,
		spotify: /(?:https?:\/\/)?(?:open\.)?spotify\.com\//,
		applePodcast: /(?:https?:\/\/)?(?:podcasts\.)?apple\.com\//,
		instagram: /(?:https?:\/\/)?(?:www\.)?instagram\.com\//,
	},
	INVESTIGATION_STATUS: {
		PENDING: 'pending',
		UPHELD: 'upheld',
		RESOLVED: 'resolved',
		CANCELLED: 'cancelled',
		PROCESSED: 'processed',
	},
}

export default CONSTANTS
