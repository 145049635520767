<template>
	<div class="position-relative">
		<div>
			<div class="d-flex align-items-center justify-content-start mb-3">
				<label class="text-bold mr-2 mb-0">{{ $t('Enable Countdown Timer') }}</label>
				<AppToggle v-model="value.enabled" />
			</div>
			<AppAccordion>
				<AppAccordionItem class="mb-0 overflow-visible shadow-none" :showHeader="false" :isWrapper="true" :name="accordionId">
					<div class="d-flex flex-column mb-3">
						<label class="control-label">Countdown Description</label>
						<AppInput
							class="form-group mb-0"
							maxlength="50"
							:showCharacterCount="false"
							placeholder="Countdown Description Here"
							v-model="value.title"
						/>
					</div>
					<div class="d-flex mb-3 d-flex flex-row mb-3 gap-15px" :key="resetKey">
						<div class="flex-50">
							<AppFlatPickr
								ref="calendar"
								custom_class="form-control"
								placeholder="End Date"
								v-model="endDate"
								:min_date="minDateStart"
								@onDateSelect="onDateTimeSelected"
								:class="{ 'form-error': validation.end_date_time.$error }"
							/>
						</div>
						<div class="flex-50">
							<div class="datetime-dropdown time" :class="{ 'form-error': validation.end_date_time.$error }">
								<AppDropdown
									class="form-control py-0"
									placeholder="End Time"
									:options="startTimeOptions"
									:searchable="false"
									label="name"
									reduce="value"
									:rightAlign="false"
									:disabled="startTimeOptions.length < 1"
									v-model="endTimeValue"
									@select="onDateTimeSelected"
								/>
							</div>
						</div>
					</div>
				</AppAccordionItem>
			</AppAccordion>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { format, parseISO, addMinutes, setSeconds, setMilliseconds, isSameDay, parse } from 'date-fns'
	import { getTimesValue, timesArray, getTimesAPIValue } from '~/pages/stores/components/BaseDefaults.js'

	export default {
		name: 'CountdownTimerForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			pageId: {
				type: Number,
				default: -1,
			},
		},
		mounted() {
			if (this.value?.enabled) {
				if (this.value?.end_date_time) {
					const date = parseISO(this.value.end_date_time)
					this.endDate = format(date, this.$constants.DATE_FORMAT.ISO_8601_DATE)
					this.endTimeValue = getTimesValue(format(date, 'hh:mm a'))
					this.resetKey++
				}
			}
			this.checkEnabled()
		},
		data() {
			return {
				accordionId: 'countdown-timer',
				endDate: undefined,
				endTimeValue: undefined,
				resetKey: 0,
				timerOptions: [
					{ name: 'absolute', value: 'absolute', label: 'Date & Time', mobileLabel: 'Date & Time' },
					{ name: 'relative', value: 'relative', label: 'Timeframe (mins/hrs)', mobileLabel: 'Timeframe (mins/hrs)' },
				],
				timerOption: 'absolute',
			}
		},
		computed: {
			...mapGetters('Stores', ['getStore']),
			...mapGetters('Auth', ['user']),
			isAffiliateShareAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('affiliate-share') !== -1
			},
			minDateStart() {
				return new Date()
			},
			startTimeOptions() {
				if (this.endDate) {
					const date = parseISO(this.endDate)
					const slotTime = this.getNextDateOnThe15(new Date())
					const timeValue = getTimesValue(format(slotTime, 'hh:mm a'))
					return this.getDateTimeOptions(date, new Date(), timeValue, true)
				}
				return []
			},
		},
		methods: {
			getNextDateOnThe15(date) {
				const minutes = date.getMinutes()
				const addMin = 15 - (minutes % 15)
				const roundedDate = addMinutes(date, addMin !== 15 ? addMin : 0)
				const nextDate = setSeconds(setMilliseconds(roundedDate, 0), 0)
				return nextDate
			},
			getDateTimeOptions(date, compareDate, timeValue, inclusive) {
				let allTimeslots = timesArray
				if (isSameDay(date, compareDate) && timeValue !== undefined) {
					allTimeslots = allTimeslots.filter(s => (inclusive ? s.value >= timeValue : s.value > timeValue))
				}
				return allTimeslots
			},
			async onDateTimeSelected() {
				await this.$nextTick()
				this.value.timer_option = 'absolute'
				this.value.end_date_time = parse(
					`${this.endDate} ${getTimesAPIValue(this.endTimeValue)}`,
					this.$constants.DATE_FORMAT.DATE_TIME_FORMAT_AM_PM,
					new Date()
				)

				if (this.value.end_date_time) {
					this.value.enabled = true
				}
			},
			checkEnabled() {
				if (this.value.enabled) {
					this.resetKey++
				}
				$(`#collapse${this.accordionId}`).collapse(this.value.enabled ? 'show' : 'hide')
			},
		},
		watch: {
			'value.enabled': {
				deep: true,
				immediate: true,
				handler() {
					this.checkEnabled()
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.flex-50 {
		flex: 1 1 0;
	}
</style>
