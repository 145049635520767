var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", { attrs: { name: _vm.animation } }, [
    _vm.showing
      ? _c("div", { staticClass: "full-screen" }, [
          _c("div", { staticClass: "mt-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center flex-column justify-content-center full-view-height",
                attrs: { id: "site_preview" }
              },
              [
                _c("AppLottie", {
                  staticClass: "loading-animation",
                  attrs: { name: "store_loading", autoPlay: "", loop: "" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "subheader-text mt-3" }, [
                  _vm._v("Entering your store...")
                ])
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }