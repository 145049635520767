const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/income',
	name: 'IncomeIndex',
	redirect: '/income',
	component: MainContainer,
	props: true,
	children: [
		{
			path: '',
			name: 'income',
			component: () => import('./Income').then(m => m.default || m),
			meta: {
				pageTitle: 'Income',
				breadcrumbs: [{ title: 'My Income', active: true }],
				roleSelector: true,
				showAppBanner: true,
			},
		},
		{
			path: 'details/:paymentId',
			name: 'payment_details',
			component: () => import('./PaymentDetails').then(m => m.default || m),
			props: true,
			meta: {
				pageTitle: 'Overview',
				breadcrumbs: [
					{ title: 'My Income', to: 'income' },
					{ title: 'Overview', active: true },
				],
			},
		},
	],
}

export default moduleRoutes
