import userflow from 'userflow.js'
import Cookies from 'js-cookie'
import axios from 'axios'

import { configSettings } from '~/plugins/config-settings.js'
import commonHelper from '~/global_helper/helpers.js'

export default {
	InitializeUserflow() {
		if (configSettings.env_name === 'production') {
			userflow.init('ct_gn77pva5ajcwthimoqmhgcmdee')
		} else {
			userflow.init('ct_jvvpyxqbabd6fdu5ikielea7ra')
		}
	},
	UpdateUserflowIdentityData(user) {
		if (user) {
			const userData = {
				name: user.full_name,
				username: user.username,
				email: user.email,
				userId: user.user_id,
				signed_up_at: new Date(user.created_at * 1000).toISOString().split('T')[0],
				profile_image: commonHelper.isExistingUserImageDefault(user?.data.profile_image) ? null : user?.data.profile_image,
			}
			const acquisition = user.data?.acquisition
			if (acquisition?.utm_source) {
				userData.utm_source = acquisition.utm_source
			}
			if (acquisition?.utm_medium) {
				userData.utm_medium = acquisition.utm_medium
			}
			if (acquisition?.utm_campaign) {
				userData.utm_campaign = acquisition.utm_campaign
			}
			userData.mobile_browser = commonHelper.isMobile()
			userflow.identify(user.user_id, userData)
		}
	},
	UserflowCustomUpdate(event, options) {
		const obj = {}
		obj[event] = options
		userflow.updateUser(obj)
	},
	UserflowCustomBulkUpdate(obj) {
		// obj = { key: value }
		userflow.updateUser(obj)
	},

	TrackUserflowEvent(event, options) {
		if (userflow.isIdentified()) {
			userflow.track(event, options)
		}
	},
}

export function addUserToExperiment(experimentName, notify = null) {
	const token = Cookies.get('token')

	if (!token) {
		console.error('Authorization token not found in cookies.')
		if (notify)
			notify({
				type: 'error', // can be 'info', 'warning', 'success', 'error'
				title: 'Oops!',
				text: 'Something went wrong! Please email friends@stanwith.me',
				duration: 4000,
			})
		return
	}

	const apiEndpoint = '/v1/userflow/add-to-experiment'
	const payload = { experiment_name: experimentName }

	axios
		.post(apiEndpoint, payload, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
		.then(() => {
			window.location.reload()
		})
		.catch(error => {
			console.error('Request failed:', error.response ? error.response.data : error.message)
			if (notify)
				notify({
					type: 'error', // can be 'info', 'warning', 'success', 'error'
					title: 'Oops!',
					text: 'Something went wrong! Please email friends@stanwith.me',
					duration: 4000,
				})
		})
}
