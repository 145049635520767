var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reviews-wrapper" },
    [
      _c(
        "div",
        { ref: "reviewsContainer", staticClass: "reviews" },
        _vm._l(_vm.reviews, function(review, index) {
          return _c(
            "div",
            { key: index, staticClass: "item" },
            [
              _c("star-rating", {
                attrs: {
                  "star-size": 12,
                  increment: 0.5,
                  "max-rating": 5,
                  "show-rating": false,
                  "read-only": true,
                  "active-color": "#FEC337",
                  animate: true
                },
                model: {
                  value: review.rating,
                  callback: function($$v) {
                    _vm.$set(review, "rating", $$v)
                  },
                  expression: "review.rating"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "image-star-rating d-none",
                  style: { "--avg-rating": review.rating }
                },
                [
                  _vm._l(Math.ceil(review.rating), function(index) {
                    return _c(
                      "svg",
                      {
                        attrs: {
                          width: "13",
                          height: "13",
                          viewBox: "0 0 13 13",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z",
                            fill: "#FEC337"
                          }
                        })
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(review.rating))])
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "heading" },
                [
                  review.image
                    ? _c("image-preview", {
                        staticClass: "image",
                        attrs: {
                          source: review.image,
                          width: 35,
                          height: 35,
                          alt: "review image"
                        }
                      })
                    : _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg",
                            alt: "reviewer frame"
                          }
                        })
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-rating-wrapper" }, [
                    _c("p", [_vm._v(_vm._s(review.name))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "reviewDescriptions",
                  refInFor: true,
                  staticClass: "review-description small-review"
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(review.description) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _vm.overflowMap[index]
                ? _c(
                    "button",
                    {
                      staticClass: "show-more-btn",
                      class: { disabled: _vm.isPreview },
                      on: {
                        click: function($event) {
                          return _vm.openModal(index)
                        }
                      }
                    },
                    [_c("span", [_vm._v("Show More")])]
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.reviews.length > 0 && !(_vm.disableLeft && _vm.disableRight)
        ? _c("div", { staticClass: "button-area-reviews" }, [
            _c(
              "button",
              {
                staticClass: "nav-btn left-arrow",
                staticStyle: { "margin-right": "8px" },
                attrs: { disabled: _vm.disableLeft },
                on: { click: _vm.scrollLeft }
              },
              [
                _c(
                  "svg",
                  {
                    style: { opacity: _vm.disableLeft ? 0.4 : 1 },
                    attrs: {
                      width: "32",
                      height: "32",
                      viewBox: "0 0 32 32",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M15.1968 23.3633C14.9039 23.6562 14.429 23.6562 14.1361 23.3633L7.46954 16.6968C7.32888 16.5562 7.24986 16.3654 7.24986 16.1665C7.24986 15.9676 7.32888 15.7768 7.46953 15.6362L14.1361 8.96958C14.429 8.67669 14.9039 8.67669 15.1968 8.96958C15.4897 9.26247 15.4897 9.73735 15.1968 10.0302L9.81052 15.4165L23.9998 15.4165C24.414 15.4165 24.7498 15.7523 24.7498 16.1665C24.7498 16.5807 24.414 16.9165 23.9998 16.9165H9.81053L15.1968 22.3027C15.4897 22.5956 15.4897 23.0704 15.1968 23.3633Z",
                        fill: "var(--stan-store-theme-primary-text-color)"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "nav-btn right-arrow",
                attrs: { disabled: _vm.disableRight },
                on: { click: _vm.scrollRight }
              },
              [
                _c(
                  "svg",
                  {
                    style: { opacity: _vm.disableRight ? 0.4 : 1 },
                    attrs: {
                      width: "32",
                      height: "32",
                      viewBox: "0 0 32 32",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M16.803 8.96967C17.0959 8.67678 17.5707 8.67678 17.8636 8.96967L24.5302 15.6362C24.6709 15.7768 24.7499 15.9676 24.7499 16.1665C24.7499 16.3654 24.6709 16.5562 24.5302 16.6969L17.8636 23.3634C17.5708 23.6563 17.0959 23.6563 16.803 23.3634C16.5101 23.0705 16.5101 22.5957 16.803 22.3028L22.1892 16.9165H8C7.58579 16.9165 7.25 16.5807 7.25 16.1665C7.25 15.7523 7.58579 15.4165 8 15.4165H22.1892L16.803 10.0303C16.5101 9.73744 16.5101 9.26257 16.803 8.96967Z",
                        fill: "var(--stan-store-theme-primary-text-color)"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-modal" } }, [
        _vm.showModal
          ? _c(
              "div",
              {
                staticClass: "review-modal-backdrop reviews",
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.closeModal.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    key: _vm.selectedReviewIndex,
                    staticClass: "review-modal item"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-review-content" },
                      [
                        _c("star-rating", {
                          attrs: {
                            "star-size": 12,
                            increment: 0.5,
                            "max-rating": 5,
                            "show-rating": false,
                            "read-only": true,
                            "active-color": "#FEC337",
                            animate: true
                          },
                          model: {
                            value: _vm.selectedReview.rating,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedReview, "rating", $$v)
                            },
                            expression: "selectedReview.rating"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "image-star-rating d-none",
                            style: { "--avg-rating": _vm.selectedReview.rating }
                          },
                          [
                            _vm._l(
                              Math.ceil(_vm.selectedReview.rating),
                              function(index) {
                                return _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "13",
                                      height: "13",
                                      viewBox: "0 0 13 13",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z",
                                        fill: "#FEC337"
                                      }
                                    })
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.selectedReview.rating))
                            ])
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "heading" },
                          [
                            _vm.selectedReview.image
                              ? _c("image-preview", {
                                  staticClass: "image",
                                  attrs: {
                                    source: _vm.selectedReview.image,
                                    width: 35,
                                    height: 35,
                                    alt: "review image"
                                  }
                                })
                              : _c("div", { staticClass: "image" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg",
                                      alt: "reviewer frame"
                                    }
                                  })
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "title-rating-wrapper" }, [
                              _c("p", [_vm._v(_vm._s(_vm.selectedReview.name))])
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "close-btn",
                                on: { click: _vm.closeModal }
                              },
                              [
                                _c("CloseSvg", {
                                  attrs: {
                                    fill:
                                      "var(--review-text-color, var(--stan-store-theme-primary-text-color))"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "review-description" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.selectedReview.description) +
                              "\n\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "nav-area" }, [
                          _c("div", { staticClass: "progress" }, [
                            _vm._v(
                              _vm._s(_vm.selectedReviewIndex + 1) +
                                " / " +
                                _vm._s(_vm.reviews.length)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "button-area" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-btn left-arrow",
                                attrs: {
                                  disabled: !(_vm.selectedReviewIndex > 0)
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.goLeft.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("LeftArrow", {
                                  style: {
                                    opacity: !(_vm.selectedReviewIndex > 0)
                                      ? 0.5
                                      : 1
                                  },
                                  attrs: {
                                    fill:
                                      "var(--review-text-color, var(--stan-store-theme-primary-text-color))"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "nav-btn right-arrow",
                                attrs: {
                                  disabled: !(
                                    _vm.selectedReviewIndex <
                                    _vm.reviews.length - 1
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.goRight.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("RightArrow", {
                                  style: {
                                    opacity: !(
                                      _vm.selectedReviewIndex <
                                      _vm.reviews.length - 1
                                    )
                                      ? 0.5
                                      : 1
                                  },
                                  attrs: {
                                    fill:
                                      "var(--review-text-color, var(--stan-store-theme-primary-text-color))"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }