var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.resetKey,
      staticClass: "countdown-timer d-flex flex-column py-5 align-items-center",
      class: { "d-none": !_vm.isCounterAvailable }
    },
    [
      _vm.counterTitle
        ? _c("div", { staticClass: "header" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.counterTitle) + "\n\t")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "stan-countdown-timer overflow-visible" }, [
        _vm.days ? _c("div", [_vm._v(_vm._s(_vm.days) + "d")]) : _vm._e(),
        _vm._v(" "),
        _vm.hours ? _c("div", [_vm._v(_vm._s(_vm.hours) + "h")]) : _vm._e(),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.minutes) + "m")]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.seconds) + "s")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }